import { FormInstance } from 'antd'
import { Dispatch, SetStateAction } from 'react'

import ContentView from './VAC/ContentView'
import TableView from './VAC/TableView'

import { useQueries } from '@tanstack/react-query'
import {
	locationsQuery,
	modelsQuery,
	profilesQuery,
	robotsQuery,
	sitesQuery
} from '../../api/queries'

interface IContentViewPropsType {
	setIsOpen: Dispatch<SetStateAction<string>>
	setModel: Dispatch<any>
	form: FormInstance<any>
}

function Content({ setIsOpen, setModel, form }: IContentViewPropsType) {
	const queriesResult = useQueries({
		queries: [
			locationsQuery,
			modelsQuery,
			profilesQuery,
			robotsQuery,
			sitesQuery
		]
	})
	const [
		getLocationsQuery,
		getModelsQuery,
		getProfilesQuery,
		getRobotsQuery,
		getSitesQuery
	] = queriesResult

	const TableViewProps = {
		setIsOpen,
		setModel,
		form,
		locationsData: getLocationsQuery.data,
		modelsData: getModelsQuery.data,
		profilesData: getProfilesQuery.data,
		robotsData: getRobotsQuery.data,
		sitesData: getSitesQuery.data
	}

	if (queriesResult.some(queries => queries.isLoading)) {
		return <span>Loading...</span>
	}

	return (
		<ContentView>
			<TableView {...TableViewProps} />
		</ContentView>
	)
}

export default Content
