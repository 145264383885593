import request from '../../lib/request'

const modelsRequest = {
	getModels: async () => {
		return await request({
			method: 'get',
			url: '/Models'
		})
	},

	getModel: async (variables: any) => {
		const { modelId } = variables

		return await request({
			method: 'get',
			url: `/Models/${modelId}`
		})
	},

	create: async (variables: any) => {
		return await request({
			method: 'post',
			url: `/Models`,
			data: JSON.stringify({
				...variables
			})
		})
	},

	update: async (variables: any) => {
		const { modelId } = variables

		return await request({
			method: 'patch',
			url: `/Models/${modelId}`,
			data: JSON.stringify({
				...variables
			})
		})
	},

	delete: async (variables: any) => {
		const { modelId } = variables

		return await request({
			method: 'delete',
			url: `/Models/${modelId}`
		})
	}
}

export default modelsRequest
