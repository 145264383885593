import { FormInstance } from 'antd'
import { Dispatch, SetStateAction } from 'react'

import Modal from '../Common/Modal'
import UserRobotUpdateModalFormView from './VAC/UserRobotUpdateModalFormView'

import { useQueries } from '@tanstack/react-query'
import { locationsQuery, profilesQuery, robotsQuery } from '../../api/queries'
import useReactMutation from '../../hooks/useReactMutation'

interface IProfileMutationModalViewPropsType {
	isOpen: string
	managedRobotIds: string[]
	setIsOpen: Dispatch<SetStateAction<string>>
	setManagedRobotIds: Dispatch<React.SetStateAction<string[]>>
	form: FormInstance<any>
}

interface IModalProps {
	size: 'small' | 'large'
	isOpen: boolean
	title: string
	children?: any
	handleCancel: () => void
	handleOk: () => void
}

interface IVFormProps {
	managedRobotIds: string[]
	form: FormInstance<any>
	robotsData: any
	profilesData: any
	locationsData: any
	handleSelectChange: (target: any) => void
}

function UserRobotUpdateModal({
	isOpen,
	managedRobotIds,
	setIsOpen,
	setManagedRobotIds,
	form
}: IProfileMutationModalViewPropsType) {
	const { usersMutation } = useReactMutation()
	const queriesResult = useQueries({
		queries: [locationsQuery, profilesQuery, robotsQuery]
	})
	const [getLocationsQuery, getProfilesQuery, getRobotsQuery] = queriesResult

	const ModalProps: IModalProps = {
		size: 'small',
		isOpen: isOpen === '관리로봇 설정',
		title: isOpen,
		handleCancel: () => {
			form.resetFields()

			setIsOpen('')
		},
		handleOk: () => {
			form
				.validateFields()
				.then(values => {
					const userId = form.getFieldValue('userId')
					const { managedRobotIds } = values

					usersMutation.updateUser.mutate({
						userId,
						managedRobotIds
					})

					form.resetFields()
					setIsOpen('')
				})
				.catch(info => {
					console.log('Validate Failed:', info)
				})
		}
	}

	const vFormProps: IVFormProps = {
		managedRobotIds,
		form,
		locationsData: getLocationsQuery.data,
		profilesData: getProfilesQuery.data,
		robotsData: getRobotsQuery.data,
		handleSelectChange: ({ target }: any) => {
			const robotId: string = target.value

			if (managedRobotIds.includes(robotId)) {
				setManagedRobotIds(managedRobotIds.filter(id => id !== robotId))
			} else {
				setManagedRobotIds(prev => [...prev, robotId])
			}
		}
	}

	if (queriesResult.some(queries => queries.isLoading)) {
		return <span>Loading...</span>
	}

	return (
		<Modal {...ModalProps}>
			<UserRobotUpdateModalFormView {...vFormProps} />
		</Modal>
	)
}

export default UserRobotUpdateModal
