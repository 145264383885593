import { Col, Row, Typography } from 'antd'

function VProfileDeleteModal({ deleteTarget }: any) {
	return (
		<Row justify="center">
			<Col
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}>
				<Typography.Text type="secondary" style={{ fontSize: '13px' }}>
					{deleteTarget.modelName}
				</Typography.Text>

				<Typography.Text strong style={{ fontSize: '16px' }}>
					{deleteTarget.alias}
				</Typography.Text>

				<Typography.Text style={{ fontSize: '14px', marginTop: '15px' }}>
					{'삭제하시겠습니까?'}
				</Typography.Text>
			</Col>
		</Row>
	)
}

export default VProfileDeleteModal
