import { Avatar, Button, Col, Row, Space, Table, Typography } from 'antd'
import Column from 'antd/lib/table/Column'

function TableView({
	setIsOpen,
	setManagedRobotIds,
	form,
	locationsData,
	profilesData,
	sitesData,
	usersData
}: any) {
	return (
		<Table
			dataSource={usersData}
			pagination={false}
			rowKey={record => record.id}>
			<Column
				title="역할"
				dataIndex="role"
				key="role"
				width="10%"
				align="center"
			/>

			<Column
				title="이름"
				dataIndex="userName"
				key="userName"
				width="30%"
				align="center"
				render={(userName, user: any, index) => {
					const firstSpellingOfName = userName[0]
					const colors: string[] = [
						'#f43f5e',
						'#475569',
						'#0D3276',
						'#f59e0b',
						'#94a3b8',
						'#38bdf8',
						'#fbcfe8',
						'#34d399',
						'#f472b6',
						'#facc15',
						'#f87171',
						'#a1a1aa'
					]
					const colorIdx = String(index).length < 2 ? index : String(index)[1]

					return (
						<Row justify="center" align="middle">
							<Col flex={'50px'}>
								<Avatar
									size={50}
									style={{
										color: 'white',
										backgroundColor: `${colors[+colorIdx]}`
									}}>
									{firstSpellingOfName}
								</Avatar>
							</Col>
							<Col
								flex={'200px'}
								style={{ textAlign: 'left', marginLeft: '15px' }}>
								<Space direction="vertical" size={1}>
									<Typography.Text style={{ fontSize: '16px' }}>
										{userName}
									</Typography.Text>

									<Typography.Text
										type="secondary"
										style={{ fontSize: '12px' }}>
										{user.email}
									</Typography.Text>
								</Space>
							</Col>
						</Row>
					)
				}}
			/>

			<Column
				title="운영구역"
				dataIndex="area"
				key="area"
				width="20%"
				align="center"
				render={(_, user: any) => {
					if (user?.managedRobots.length) {
						// 서버 리턴 로봇, API 데이터 아님
						const robot = user.managedRobots[0]
						const profile = profilesData?.filter(
							(profile: any) => profile.id === robot?.profile_id
						)[0]
						const location = locationsData?.filter(
							(location: any) => location.id === profile?.locationId
						)[0]
						const site = sitesData?.filter(
							(site: any) => site.id === location?.siteId
						)[0]

						return site?.siteName
					} else {
						return '-'
					}
				}}
			/>

			<Column
				title="운영로봇"
				dataIndex="robot"
				key="robot"
				width="20%"
				align="center"
				render={(_, user: any) => {
					if (user?.managedRobots.length) {
						return `${user.managedRobots.length}대`
					} else {
						return '-'
					}
				}}
			/>

			<Column
				title="설정"
				key="action"
				width="20%"
				align="center"
				render={(user: any) => {
					// 현재 새로운 유저를 만들면 managedRobotIds 값을 서버에서 []<string>을 넣어준다.
					// 차후 이 로직을 제거하고 프론트에서 직접 []<object>를 입력한다.
					let $managedRobotIds: any = []

					if (typeof user?.managedRobotIds === 'string') {
						$managedRobotIds = []
					} else {
						$managedRobotIds = user?.managedRobotIds
					}

					return (
						<>
							<Button
								type="text"
								onClick={() => {
									form.setFieldsValue({
										userId: user?.id,
										phoneNumber: user?.phoneNumber,
										role: user?.role,
										userName: user?.userName
									})

									setIsOpen('사용자 설정')
								}}
								style={{ color: 'blue', fontSize: '12px' }}>
								사용자설정
							</Button>
							<Button
								type="text"
								onClick={() => {
									const managedRobotIds = $managedRobotIds.map(
										(managedRobotId: string) => String(managedRobotId)
									)

									form.setFieldsValue({
										userId: user?.id,
										managedRobotIds
									})

									setManagedRobotIds(managedRobotIds)
									setIsOpen('관리로봇 설정')
								}}
								style={{ color: 'blue', fontSize: '12px' }}>
								관리로봇설정
							</Button>
							<Button
								type="text"
								onClick={() => {
									form.setFieldsValue({
										userId: user?.id,
										role: user?.role,
										userName: user?.userName
									})

									setIsOpen('사용자 삭제')
								}}
								style={{ color: 'red', fontSize: '12px' }}>
								삭제
							</Button>
						</>
					)
				}}
			/>
		</Table>
	)
}

export default TableView
