import { FormInstance } from 'antd'
import { Dispatch, SetStateAction, useState } from 'react'

import {
	IRobotModelDataType,
	IRobotProfileDataType
} from '../../types/interfaces'

import Modal from '../../components/Common/Modal'
import ProfileMutationModalFormView from './VAC/ProfileMutationModalFormView'

import { useQuery } from '@tanstack/react-query'
import { modelsQuery, sitesQuery } from '../../api/queries'
import useReactMutation from '../../hooks/useReactMutation'

interface IProfileMutationModalViewPropsType {
	isOpen: string
	model: IRobotModelDataType | null
	profile: IRobotProfileDataType | null
	setProfile: Dispatch<React.SetStateAction<IRobotProfileDataType | null>>
	setModel: Dispatch<React.SetStateAction<IRobotModelDataType | null>>
	setIsOpen: Dispatch<SetStateAction<string>>
	form: FormInstance<any>
}

interface IModalProps {
	size: 'small' | 'large'
	isOpen: boolean
	title: string
	children?: any
	handleCancel: () => void
	handleOk: () => void
}

interface IVFormProps {
	model: IRobotModelDataType | null
	profile: IRobotProfileDataType | null
	form: FormInstance<any>
	robotStateImg: { onStateCustomImgUrl: string; offStateCustomImgUrl: string }
	modelsData: any
	sitesData: any
	handleSelectChange: (value: any) => void
	handleUploadOnStateCustomImg: (fileList: any) => void
	handleUploadOffStateCustomImg: (fileList: any) => void
}

// interface IUploadFile {
// 	[index: string]: UploadFile[]
// }

function ProfileMutationModal({
	isOpen,
	model,
	profile,
	setIsOpen,
	setModel,
	setProfile,
	form
}: IProfileMutationModalViewPropsType) {
	const [robotStateImg, setRobotStateImg] = useState({
		onStateCustomImgUrl: '',
		offStateCustomImgUrl: ''
	})

	const { isLoading: getModelsIsLoading, data: modelsData } =
		useQuery(modelsQuery)
	const { isLoading: getSitesIsLoading, data: sitesData } = useQuery(sitesQuery)
	const { profilesMutation } = useReactMutation()

	const ModalProps: IModalProps = {
		size: 'small',
		isOpen: isOpen === '로봇 프로필 설정' || isOpen === '로봇 프로필 추가',
		title: isOpen,
		handleCancel: () => {
			setProfile(null)
			setModel(null)
			setRobotStateImg({
				onStateCustomImgUrl: '',
				offStateCustomImgUrl: ''
			})

			form.resetFields()

			setIsOpen('')
		},
		handleOk: () => {
			form
				.validateFields()
				.then(values => {
					const {
						profileId,
						onStateCustomImgUrl: $onStateCustomImgUrl,
						offStateCustomImgUrl: $offStateCustomImgUrl
					} = form.getFieldsValue(true)

					const { modelId, locationId, alias } = values
					const { onStateCustomImgUrl, offStateCustomImgUrl } = robotStateImg

					if (isOpen === '로봇 프로필 추가') {
						profilesMutation.createProfile.mutate({
							modelId,
							locationId,
							alias,
							onStateCustomImgUrl: onStateCustomImgUrl
								? onStateCustomImgUrl
								: model?.onStateDefaultImgUrl,
							offStateCustomImgUrl: offStateCustomImgUrl
								? offStateCustomImgUrl
								: model?.offStateDefaultImgUrl
						})
					} else if (isOpen === '로봇 프로필 설정') {
						profilesMutation.updateProfile.mutate({
							profileId,
							modelId,
							locationId,
							alias,
							onStateCustomImgUrl: onStateCustomImgUrl
								? onStateCustomImgUrl
								: $onStateCustomImgUrl,
							offStateCustomImgUrl: offStateCustomImgUrl
								? offStateCustomImgUrl
								: $offStateCustomImgUrl
						})
					}

					form.resetFields()
					setIsOpen('')
					setRobotStateImg({
						onStateCustomImgUrl: '',
						offStateCustomImgUrl: ''
					})
				})
				.catch(info => {
					console.log('Validate Failed:', info)
				})
		}
	}

	const vFormProps: IVFormProps = {
		model,
		profile,
		form,
		robotStateImg,
		modelsData,
		sitesData,
		handleSelectChange: (value: any) => {
			const modelId = value
			const model = modelsData?.filter((model: any) => model.id === modelId)[0]

			if (model) {
				setProfile(null)
				setModel(model)
			}
		},
		handleUploadOnStateCustomImg: (info: any) => {
			// if (info.file.status !== 'uploading') {
			// 	console.log(info.file, info.fileList)
			// }
			if (info.file.status === 'done') {
				setRobotStateImg(prev => {
					return { ...prev, onStateCustomImgUrl: info.file.response }
				})

				// console.log(`${info.file.name} file uploaded successfully`)
			} else if (info.file.status === 'error') {
				console.log(`${info.file.name} file upload failed.`)
			}
		},
		handleUploadOffStateCustomImg: (info: any) => {
			// if (info.file.status !== 'uploading') {
			// 	console.log(info.file, info.fileList)
			// }
			if (info.file.status === 'done') {
				setRobotStateImg(prev => {
					return { ...prev, offStateCustomImgUrl: info.file.response }
				})

				// console.log(`${info.file.name} file uploaded successfully`)
			} else if (info.file.status === 'error') {
				console.log(`${info.file.name} file upload failed.`)
			}
		}
	}

	if (getModelsIsLoading && getSitesIsLoading) {
		return <span>Loading...</span>
	}

	return (
		<Modal {...ModalProps}>
			<ProfileMutationModalFormView {...vFormProps} />
		</Modal>
	)
}

export default ProfileMutationModal
