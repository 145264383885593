import { Col, Layout, Row } from 'antd'
import ContentView from './Content'
import PageHeaderView from './PageHeader'
import TextView from './Text'

function Login() {
	return (
		<>
			<Layout
				style={{
					width: '650px',
					margin: 'auto',
					height: '100vh',
					justifyContent: 'center'
				}}>
				<Row justify="center">
					<Col>
						<PageHeaderView />
						<ContentView />
						<TextView />
					</Col>
				</Row>
			</Layout>
		</>
	)
}

export default Login
