import request from '../../lib/request'

const summariesRequest = {
	getSummaries: async () => {
		return await request({
			method: 'get',
			url: '/Summaries'
		})
	},

	getSummary: async (variables: any) => {
		const { summariesId } = variables

		return await request({
			method: 'get',
			url: `/Summaries/${summariesId}`
		})
	},

	create: async (variables: any) => {
		const {
			point,
			robotCount,
			flagshipStore,
			routeDistance,
			routeTime,
			videoCount
		} = variables

		return await request({
			method: 'post',
			url: `/Summaries`,
			data: JSON.stringify({
				point,
				robotCount,
				flagshipStore,
				routeDistance,
				routeTime,
				videoCount
			})
		})
	},

	update: async (variables: any) => {
		const {
			summaryId,
			point,
			robotCount,
			flagshipStore,
			routeDistance,
			routeTime,
			videoCount
		} = variables

		return await request({
			method: 'patch',
			url: `/Summaries/${summaryId}`,
			data: JSON.stringify({
				point,
				robotCount,
				flagshipStore,
				routeDistance,
				routeTime,
				videoCount
			})
		})
	},

	delete: async (variables: any) => {
		const { summaryId } = variables

		return await request({
			method: 'delete',
			url: `/Summaries/${summaryId}`
		})
	}
}

export default summariesRequest
