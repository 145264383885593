import request from '../../lib/request'

const adminsRequest = {
	getAdmin: async () => {
		return await request({
			method: 'get',
			url: '/Admin'
		})
	},

	updatePassword: async (variables: any) => {
		const { adminId, password } = variables

		return await request({
			method: 'patch',
			url: `/Admin/${adminId}`,
			data: JSON.stringify({ password })
		})
	},

	updateInfo: async (variables: any) => {
		const { adminId, adminName, phoneNumber } = variables

		return await request({
			method: 'patch',
			url: `/Admin/${adminId}`,
			data: JSON.stringify({ adminName, phoneNumber })
		})
	}
}

export default adminsRequest
