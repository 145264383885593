import request from '../../lib/request'

const usersRequest = {
	getUsers: async () => {
		return await request({
			method: 'get',
			url: '/Users'
		})
	},

	getUser: async (variables: any) => {
		const { userId } = variables

		return await request({
			method: 'get',
			url: `/Users/${userId}`
		})
	},

	create: async (variables: any) => {
		const { email, password, role, userName, phoneNumber } = variables

		return await request({
			method: 'post',
			url: `/Users`,
			data: JSON.stringify({
				email,
				password,
				role,
				userName,
				phoneNumber,
				test: []
			})
		})
	},

	update: async (variables: any) => {
		const { userId, role, userName, phoneNumber, managedRobotIds } = variables

		let data

		if (managedRobotIds) {
			data = {
				managedRobotIds
			}
		} else {
			data = {
				role,
				userName,
				phoneNumber
			}
		}

		return await request({
			method: 'patch',
			url: `/Users/${userId}`,
			data: JSON.stringify({
				...data
			})
		})
	},

	delete: async (variables: any) => {
		const { userId } = variables

		return await request({
			method: 'delete',
			url: `/Users/${userId}`
		})
	}
}

export default usersRequest
