import { PageHeader, Typography } from 'antd'

function PageHeaderView() {
	return (
		<PageHeader
			title={
				<Typography.Title level={3} style={{ marginBottom: 0 }}>
					로봇 목록
				</Typography.Title>
			}
			style={{
				padding: '16px 0'
			}}
		/>
	)
}

export default PageHeaderView
