import { FormInstance } from 'antd'
import { Dispatch, SetStateAction } from 'react'

import Modal from '../../components/Common/Modal'
import LocationMutationModalFormView from '../../components/Site/VAC/LocationMutationModalFormView'

import useReactMutation from '../../hooks/useReactMutation'

interface ILocationMutationModalViewPropsType {
	isOpen: string
	setIsOpen: Dispatch<SetStateAction<string>>
	form: FormInstance<any>
}

interface IModalProps {
	size: 'small' | 'large'
	isOpen: boolean
	title: string
	children?: any
	handleCancel: () => void
	handleOk: () => void
}

interface IVFormProps {
	form: FormInstance<any>
}

function LocationMutationModal({
	isOpen,
	setIsOpen,
	form
}: ILocationMutationModalViewPropsType) {
	const { locationsMutation } = useReactMutation()

	const ModalProps: IModalProps = {
		size: 'small',
		isOpen: isOpen.includes('운영장소'),
		title: isOpen,
		handleCancel: () => {
			form.resetFields()

			setIsOpen('')
		},
		handleOk: () => {
			form
				.validateFields()
				.then(values => {
					const siteId = form.getFieldValue('siteId')
					const locationId = form.getFieldValue('locationId')
					const { locationName, locationPoint } = values

					if (isOpen === '운영장소 추가') {
						locationsMutation.createLocation.mutate({
							siteId,
							locationName,
							locationPoint
						})
					} else if (isOpen === '운영장소 설정') {
						locationsMutation.updateLocation.mutate({
							locationId,
							locationName,
							locationPoint
						})
					}

					form.resetFields()
					setIsOpen('')
				})
				.catch(info => {
					console.log('Validate Failed:', info)
				})
		}
	}

	const vFormProps: IVFormProps = {
		form
	}

	return (
		<Modal {...ModalProps}>
			<LocationMutationModalFormView {...vFormProps} />
		</Modal>
	)
}

export default LocationMutationModal
