import { Form, Input } from 'antd'

function LocationMutationModalFormView({ form }: any) {
	return (
		<Form form={form} layout="horizontal" name="form_in_modal">
			<Form.Item
				className="in-modal"
				name="locationName"
				label="이름"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Input style={{ width: '250px', height: '50px' }} />
			</Form.Item>

			<Form.Item
				className="in-modal"
				name="locationPoint"
				label="위치"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Input style={{ width: '250px', height: '50px' }} />
			</Form.Item>
		</Form>
	)
}

export default LocationMutationModalFormView
