import { MenuProps } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import SiderMenuView from './Views/SiderMenuView'

type MenuItem = Required<MenuProps>['items'][number]

function SiderMenu() {
	const { pathname } = useLocation()
	const [keys, setKeys] = useState('')

	useEffect(() => {
		if (pathname === '/') {
			setKeys(pathname)
		} else {
			const keys = pathname.replace('/', '')

			setKeys(keys)
		}
	}, [pathname])

	const getItem = (
		label: React.ReactNode,
		key: React.Key,
		icon?: React.ReactNode,
		children?: MenuItem[],
		type?: 'group'
	): MenuItem => {
		return {
			label,
			key,
			icon,
			children,
			type
		} as MenuItem
	}

	const items: MenuItem[] = [
		getItem(<Link to="/admin">관리자 계정관리</Link>, '/'),
		{ type: 'divider' },

		getItem('회원', 'sub1', '', [
			getItem(<Link to="/users">회원관리</Link>, 'users')
		]),
		{ type: 'divider' },

		getItem('운영구역', 'sub2', '', [
			getItem(<Link to="/sites">운영구역관리</Link>, 'sites'),
			getItem(
				<Link style={{ color: '#ccc' }} to="/map">
					맵 관리
				</Link>,
				'map'
			)
		]),
		{ type: 'divider' },

		getItem('로봇', 'sub3', '', [
			getItem(<Link to="/robots">로봇 목록</Link>, 'robots'),
			getItem(<Link to="/profiles">로봇 프로필 관리</Link>, 'profiles'),
			getItem(<Link to="/models">로봇 모델 관리</Link>, 'models')
		]),
		{ type: 'divider' },

		getItem('로봇 서비스', 'sub4', '', [
			getItem(
				<Link style={{ color: '#ccc' }} to="/communication">
					기본대화관리
				</Link>,
				'communication'
			),
			getItem(
				<Link style={{ color: '#ccc' }} to="/face-app">
					FaceApp 보기
				</Link>,
				'face-app'
			),
			getItem(
				<Link style={{ color: '#ccc' }} to="/media">
					미디어 관리
				</Link>,
				'media'
			),
			getItem(
				<Link style={{ color: '#ccc' }} to="/confusion">
					혼잡도 관리
				</Link>,
				'confusion'
			),
			getItem(
				<Link style={{ color: '#ccc' }} to="/covid">
					방역 관리
				</Link>,
				'covid'
			)
		]),
		{ type: 'divider' }
	]

	const siderMenuViewProps = {
		keys,
		items
	}

	return <SiderMenuView {...siderMenuViewProps} />
}

export default SiderMenu
