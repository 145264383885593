import { Typography } from 'antd'

function TextView() {
	return (
		<Typography.Text
			type="secondary"
			style={{
				display: 'block',
				textAlign: 'center',
				fontSize: '13px',
				marginTop: '40px'
			}}>
			계정관리 02-1234-5678
		</Typography.Text>
	)
}

export default TextView
