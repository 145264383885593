import { QuestionOutlined } from '@ant-design/icons'
import { Avatar, Form, Select, Typography } from 'antd'

function RobotUpdateModalFormView({
	isOpen,
	model,
	form,
	profilesData,
	handleSelectChange
}: any) {
	return (
		<Form form={form} layout="horizontal" name="form_in_modal">
			<Form.Item
				className="in-modal"
				label="Agent ID"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{}}>
				<Typography.Text style={{ fontSize: '14px', color: 'green' }}>
					{isOpen}
				</Typography.Text>
			</Form.Item>

			<Form.Item
				className="select_in_robotList profile in-modal"
				name="profile_id"
				label="프로필"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Select
					showArrow
					allowClear
					bordered
					onChange={handleSelectChange}
					style={{ width: '250px', height: '50px' }}>
					<Select.Option
						value={''}
						style={{ height: '60px', alignText: 'center' }}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								height: '100%'
							}}>
							<Typography.Text
								style={{ fontSize: '12px', paddingLeft: '20px' }}>
								선택안함
							</Typography.Text>
						</div>
					</Select.Option>
					{profilesData?.map((profile: any) => {
						const { id, alias, onStateCustomImgUrl } = profile

						return (
							<Select.Option key={id} value={id}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										height: '100%'
									}}>
									<Avatar
										size={50}
										style={{
											color: 'white'
										}}
										src={onStateCustomImgUrl}
									/>
									<Typography.Text style={{ fontSize: '12px' }}>
										{alias}
									</Typography.Text>
								</div>
							</Select.Option>
						)
					})}
				</Select>
			</Form.Item>

			<Form.Item
				className="in-modal"
				label="모델"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{}}>
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: 'repeat(auto-fill, minmax(90px, 1fr))',
						justifyItems: 'center',
						alignItems: 'center'
					}}>
					<Avatar
						size={100}
						style={{
							color: 'white'
						}}
						src={
							model ? (
								model.onStateDefaultImgUrl
							) : (
								<Avatar
									size={50}
									style={{
										color: 'white'
									}}
									src={
										<QuestionOutlined
											style={{
												color: 'black',
												background: '#fafafa',
												border: '1px solid #f0f0f0',
												padding: '20px',
												borderRadius: '100%'
											}}
										/>
									}
								/>
							)
						}
					/>
					<Typography.Text style={{ fontSize: '14px' }}>
						{model ? model.modelName : 'Unkown'}
					</Typography.Text>
				</div>
			</Form.Item>
		</Form>
	)
}

export default RobotUpdateModalFormView
