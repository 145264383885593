import { FormInstance } from 'antd'
import { Dispatch, SetStateAction } from 'react'

import ContentView from './VAC/ContentView'
import TableView from './VAC/TableView'

import { useQuery } from '@tanstack/react-query'
import { modelsQuery } from '../../api/queries'

interface IContentViewPropsType {
	setIsOpen: Dispatch<SetStateAction<string>>
	setModel: Dispatch<any>
	setSpecialFunctions: Dispatch<React.SetStateAction<string>>
	form: FormInstance<any>
}

function Content({
	setIsOpen,
	setModel,
	setSpecialFunctions,
	form
}: IContentViewPropsType) {
	const { isLoading, data: modelsData } = useQuery(modelsQuery)

	const TableViewProps = {
		setIsOpen,
		setModel,
		setSpecialFunctions,
		form,
		modelsData
	}

	if (isLoading) {
		return <span>Loading...</span>
	}

	return (
		<ContentView>
			<TableView {...TableViewProps} />
		</ContentView>
	)
}

export default Content
