import { Form } from 'antd'
import { useState } from 'react'

import Content from './Content'
import LocationDeleteModal from './LocationDeleteModal'
import LocationMutationModal from './LocationMutationModal'
import PageHeader from './PageHeader'
import SiteDeleteModal from './SiteDeleteModal'
import SiteMutationModal from './SiteMutationModal'

function Site() {
	const [isOpen, setIsOpen] = useState('')
	const [form] = Form.useForm()

	const pageHeaderProps = {
		setIsOpen
	}
	const contentProps = {
		setIsOpen,
		form
	}
	const locationMutationModalProps = {
		isOpen,
		setIsOpen,
		form
	}
	const locationDeleteModalProps = {
		isOpen,
		setIsOpen,
		form
	}
	const siteMutationModalProps = {
		isOpen,
		setIsOpen,
		form
	}
	const siteDeleteModalProps = {
		isOpen,
		setIsOpen,
		form
	}

	return (
		<>
			<PageHeader {...pageHeaderProps} />
			<Content {...contentProps} />
			<LocationMutationModal {...locationMutationModalProps} />
			<LocationDeleteModal {...locationDeleteModalProps} />
			<SiteMutationModal {...siteMutationModalProps} />
			<SiteDeleteModal {...siteDeleteModalProps} />
		</>
	)
}

export default Site
