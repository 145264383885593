import { Button, Space, Table, Typography } from 'antd'
import Column from 'antd/lib/table/Column'

function TableView({ setIsOpen, form, sitesServiceData, summariesData }: any) {
	return (
		<Table
			dataSource={sitesServiceData}
			pagination={false}
			rowKey={record => record.id}>
			<Column
				title="ID"
				dataIndex="id"
				key="id"
				width="10%"
				align="center"
				render={(_, site: any, index) => {
					if (site?.type === 'site') {
						return index + 1
					}
				}}
			/>

			<Column
				title="모델"
				dataIndex="siteName"
				key="siteName"
				width="25%"
				align="center"
				render={(_, site: any) => {
					if (site?.type === 'site') {
						return (
							<Space direction="vertical" size={1}>
								<Typography.Text
									style={{
										fontSize: '16px'
									}}>
									{site?.siteName}
								</Typography.Text>

								<Typography.Text
									type="secondary"
									style={{ fontSize: '12px', textAlign: 'left' }}>
									{site?.address}
								</Typography.Text>
							</Space>
						)
					} else if (site?.type === 'location') {
						return (
							<Typography.Text
								style={{
									fontSize: '16px'
								}}>
								{site?.locationName}
							</Typography.Text>
						)
					}
				}}
			/>

			<Column
				title="요약정보"
				dataIndex="summary"
				key="summary"
				width="45%"
				align="center"
				render={(summary, site: any) => {
					if (site.type === 'site') {
						return (
							<Typography.Text style={{ fontSize: '14px' }}>
								운행구역: {summary?.point} | 운영로봇: {summary?.robotCount}대 |
								대표매장:
								{summary?.flagshipStore} | 경로거리: {summary?.routeDistance}m |
								운행소요시간:
								{summary?.routeTime}min | 등록영상 수: {summary?.videoCount}개
							</Typography.Text>
						)
					} else {
						return
					}
				}}
			/>

			<Column
				title="설정"
				key="action"
				width="20%"
				align="center"
				render={site => {
					if (site?.type === 'site') {
						const summary = summariesData?.filter(
							(summary: any) => summary.id === site?.summariesId
						)[0]

						return (
							<>
								<Button
									type="text"
									onClick={() => {
										form.setFieldsValue({
											siteId: site?.id
										})

										setIsOpen('운영장소 추가')
									}}
									style={{ color: 'blue', fontSize: '14px' }}>
									장소추가
								</Button>
								<Button
									type="text"
									onClick={() => {
										form.setFieldsValue({
											siteId: site?.id,
											summaryId: site?.summariesId,
											address: site?.address,
											latitude: site?.latitude,
											longitude: site?.longitude,
											siteName: site?.siteName,
											point: summary?.point,
											robotCount: summary?.robotCount,
											flagshipStore: summary?.flagshipStore,
											routeDistance: summary?.routeDistance,
											routeTime: summary?.routeTime,
											videoCount: summary?.videoCount
										})

										setIsOpen('운영그룹 설정')
									}}
									style={{ color: 'blue', fontSize: '14px' }}>
									그룹설정
								</Button>
								<Button
									type="text"
									onClick={() => {
										form.setFieldsValue({
											siteId: site?.id,
											address: site?.address,
											siteName: site?.siteName
										})

										setIsOpen('사이트 삭제')
									}}
									style={{ color: 'red', fontSize: '14px' }}>
									삭제
								</Button>
							</>
						)
					} else if (site?.type === 'location') {
						const { id: locationId, locationName, locationPoint } = site

						return (
							<>
								<Button
									type="text"
									onClick={() => {
										setIsOpen('운영장소 추가')
									}}
									style={{
										color: 'blue',
										fontSize: '14px',
										visibility: 'hidden'
									}}>
									장소추가
								</Button>
								<Button
									type="text"
									onClick={() => {
										form.setFieldsValue({
											locationId,
											locationName,
											locationPoint
										})

										setIsOpen('운영장소 설정')
									}}
									style={{
										color: 'blue',
										fontSize: '14px'
										// marginLeft: '18px'
									}}>
									장소설정
								</Button>
								<Button
									type="text"
									onClick={() => {
										form.setFieldsValue({
											locationId,
											locationName,
											locationPoint
										})

										setIsOpen('장소 삭제')
									}}
									style={{ color: 'red', fontSize: '14px' }}>
									삭제
								</Button>
							</>
						)
					}
				}}
			/>
		</Table>
	)
}

export default TableView
