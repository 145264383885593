import request from '../../lib/request'

const locationsRequest = {
	getLocations: async () => {
		return await request({
			method: 'get',
			url: '/Locations'
		})
	},

	getLocation: async (variables: any) => {
		const { locationId } = variables

		return await request({
			method: 'get',
			url: `/Locations/${locationId}`
		})
	},

	create: async (variables: any) => {
		const { siteId, locationName, locationPoint } = variables

		return await request({
			method: 'post',
			url: `/Locations`,
			data: JSON.stringify({
				site_id: siteId,
				locationName,
				locationPoint,
				type: 'location'
			})
		})
	},

	update: async (variables: any) => {
		const { locationId, locationName, locationPoint } = variables

		return await request({
			method: 'patch',
			url: `/Locations/${locationId}`,
			data: JSON.stringify({ locationName, locationPoint })
		})
	},

	delete: async (variables: any) => {
		const { locationId } = variables

		return await request({
			method: 'delete',
			url: `/Locations/${locationId}`
		})
	}
}

export default locationsRequest
