import { Form, Input } from 'antd'

function PasswordUpdateModalFormView({ form }: any) {
	return (
		<Form form={form} layout="horizontal" name="password_update_form_in_modal">
			<Form.Item
				className="in-modal"
				name="password"
				label="비밀번호"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Input.Password style={{ width: '250px', height: '50px' }} />
			</Form.Item>

			<Form.Item
				className="in-modal"
				name="validatePassword"
				label="비밀번호 확인"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				dependencies={['password']}
				style={{
					height: '50px'
				}}>
				<Input.Password style={{ width: '250px', height: '50px' }} />
			</Form.Item>
		</Form>
	)
}

export default PasswordUpdateModalFormView
