import { FileImageOutlined } from '@ant-design/icons'
import {
	Badge,
	Col,
	Divider,
	Form,
	Image,
	Input,
	Row,
	Space,
	Tag,
	Typography,
	Upload
} from 'antd'

import { SPECIAL_FUNCTIONS } from '../../../constants/model.constant'

function ModelMutationModalFormView({
	model,
	specialFunctions,
	form,
	robotStateImg,
	handleChange,
	handleUploadOnStateDefaultImg,
	handleUploadOffStateDefaultImg
}: any) {
	return (
		<Form form={form} layout="horizontal" name="form_in_modal">
			<Row>
				<Col style={{ width: '50%', height: '100%' }}>
					<Divider
						orientation="left"
						orientationMargin={0}
						plain
						style={{
							fontSize: '11px',
							fontWeight: '700',
							paddingRight: '20px'
						}}>
						모델 정보
					</Divider>

					<Form.Item
						className="in-modal"
						name="modelName"
						label="모델명"
						labelCol={{ offset: 0, span: 6 }}
						labelAlign="left"
						colon={false}
						style={{
							height: '50px'
						}}>
						<Input style={{ width: '250px', height: '50px' }} />
					</Form.Item>

					<Form.Item
						className="in-modal"
						label="모델사진"
						labelCol={{ offset: 0, span: 6 }}
						labelAlign="left"
						colon={false}
						style={{ height: '100%' }}>
						<Form.List
							name="robotStateImg"
							initialValue={[
								{ onStateDefaultImgUrl: '', offStateDefaultImgUrl: '' }
							]}>
							{fields =>
								fields.map(field => {
									return (
										<Space
											className="upload_in_robotProfile robotStateImg"
											key={field.key}
											direction="horizontal">
											<Form.Item style={{ height: '100%' }}>
												<Space direction="vertical">
													<Typography.Text
														strong
														style={{
															fontSize: '14px'
														}}>
														ON 상태
													</Typography.Text>

													<Upload
														accept="image/png, image/jpeg"
														listType="picture-card"
														maxCount={1}
														showUploadList={{
															showPreviewIcon: false
														}}
														onChange={handleUploadOnStateDefaultImg}
														action={`${process.env.REACT_APP_SERVER_API_PROD}/upload`}>
														{robotStateImg.onStateDefaultImgUrl ? null : model ? (
															<Image
																preview={false}
																src={model.onStateDefaultImgUrl}
															/>
														) : (
															<div>
																<FileImageOutlined
																	style={{ fontSize: '30px' }}
																/>
																<div
																	style={{
																		marginTop: 8
																	}}>
																	선택
																</div>
															</div>
														)}
													</Upload>
												</Space>
											</Form.Item>

											<Form.Item style={{ height: '100%' }}>
												<Space direction="vertical">
													<Typography.Text
														style={{
															fontSize: '14px',
															fontWeight: 600
														}}>
														OFF 상태
													</Typography.Text>

													<Upload
														accept="image/png, image/jpeg"
														listType="picture-card"
														maxCount={1}
														showUploadList={{
															showPreviewIcon: false
														}}
														onChange={handleUploadOffStateDefaultImg}
														action={`${process.env.REACT_APP_SERVER_API_PROD}/upload`}>
														{robotStateImg.offStateDefaultImgUrl ? null : model ? (
															<Image
																preview={false}
																src={model.offStateDefaultImgUrl}
															/>
														) : (
															<div style={{ width: '50px' }}>
																<FileImageOutlined
																	style={{ fontSize: '30px' }}
																/>
																<div
																	style={{
																		marginTop: 8
																	}}>
																	선택
																</div>
															</div>
														)}
													</Upload>
												</Space>
											</Form.Item>
										</Space>
									)
								})
							}
						</Form.List>
					</Form.Item>

					<Form.Item
						className="in-modal"
						label="주요기능설정"
						labelCol={{ offset: 0, span: 6 }}
						labelAlign="left"
						colon={false}
						style={{
							width: '100%'
						}}>
						<Space direction="horizontal" wrap>
							{SPECIAL_FUNCTIONS.map(tag => {
								const [func, color] = tag.split(':')

								return (
									<Tag.CheckableTag
										key={func}
										checked={specialFunctions.indexOf(func) > -1}
										onChange={checked => handleChange(func, checked)}
										style={{
											border: '1px solid black',
											borderRadius: '12px',
											padding: '1px 10px'
										}}>
										<Badge key={func} color={color} text={func} />
									</Tag.CheckableTag>
								)
							})}
						</Space>
					</Form.Item>
				</Col>

				<Col
					style={{
						width: '50%',
						height: '100%',
						paddingLeft: '20px'
					}}>
					<Divider
						orientation="left"
						orientationMargin={0}
						plain
						style={{ fontSize: '11px', fontWeight: '700' }}>
						제조사 정보
					</Divider>

					<Form.Item
						className="in-modal"
						name="manufacturer"
						label="제조사"
						labelCol={{ offset: 0, span: 5 }}
						labelAlign="left"
						colon={false}
						style={{
							height: '50px'
						}}>
						<Input style={{ width: '250px', height: '50px' }} />
					</Form.Item>

					<Form.Item
						className="in-modal"
						name="manufacturingDate"
						label="제조일"
						labelCol={{ offset: 0, span: 5 }}
						labelAlign="left"
						colon={false}
						style={{
							height: '50px'
						}}>
						<Input style={{ width: '250px', height: '50px' }} />
					</Form.Item>

					<Form.Item
						className="in-modal"
						name="manufacturerContact"
						label="연락처"
						labelCol={{ offset: 0, span: 5 }}
						labelAlign="left"
						colon={false}
						style={{
							height: '50px'
						}}>
						<Input style={{ width: '250px', height: '50px' }} />
					</Form.Item>

					<Form.Item
						className="in-modal"
						name="homepage"
						label="홈페이지"
						labelCol={{ offset: 0, span: 5 }}
						labelAlign="left"
						colon={false}
						style={{
							height: '50px'
						}}>
						<Input style={{ width: '250px', height: '50px' }} />
					</Form.Item>

					<Divider
						orientation="left"
						orientationMargin={0}
						plain
						style={{ fontSize: '11px', fontWeight: '700' }}>
						공급사 정보
					</Divider>

					<Form.Item
						className="in-modal"
						name="supplierName"
						label="공급사"
						labelCol={{ offset: 0, span: 5 }}
						labelAlign="left"
						colon={false}
						style={{
							height: '50px'
						}}>
						<Input style={{ width: '250px', height: '50px' }} />
					</Form.Item>

					<Form.Item
						className="in-modal"
						name="supplierContact"
						label="연락처"
						labelCol={{ offset: 0, span: 5 }}
						labelAlign="left"
						colon={false}
						style={{
							height: '50px'
						}}>
						<Input style={{ width: '250px', height: '50px' }} />
					</Form.Item>

					<Form.Item
						className="in-modal"
						name="supplierHomepage"
						label="홈페이지"
						labelCol={{ offset: 0, span: 5 }}
						labelAlign="left"
						colon={false}
						style={{
							height: '50px'
						}}>
						<Input style={{ width: '250px', height: '50px' }} />
					</Form.Item>
				</Col>
			</Row>
		</Form>
	)
}

export default ModelMutationModalFormView
