import { Button, PageHeader, Typography } from 'antd'

function PageHeaderView({ handleProfileCreateModalClick }: any) {
	return (
		<PageHeader
			title={
				<Typography.Title level={3} style={{ marginBottom: 0 }}>
					로봇 프로필
				</Typography.Title>
			}
			extra={[
				<Button
					key="1"
					type="primary"
					size="large"
					onClick={handleProfileCreateModalClick}
					style={{
						width: '150px',
						borderRadius: '10px',
						fontSize: '16.5px',
						fontWeight: 600
					}}>
					프로필 추가
				</Button>
			]}
			style={{
				padding: '16px 0'
			}}
		/>
	)
}

export default PageHeaderView
