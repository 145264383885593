import { Layout } from 'antd'

type Props = {
	children: React.ReactNode
}

function ContentView({ children }: Props) {
	return (
		<Layout.Content
			style={{
				padding: 0,
				margin: 0,
				minHeight: 280
			}}>
			{children}
		</Layout.Content>
	)
}

export default ContentView
