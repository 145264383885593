import { Form } from 'antd'
import { useState } from 'react'

import { IRobotModelDataType } from '../../types/interfaces'
import Content from './Content'
import ModelDeleteModal from './ModelDeleteModal'
import ModelMutationModal from './ModelMutationModal'
import PageHeader from './PageHeader'

function Model() {
	const [isOpen, setIsOpen] = useState('')
	const [model, setModel] = useState<IRobotModelDataType | null>(null)
	const [specialFunctions, setSpecialFunctions] = useState('')
	// const [previewOpen, setPreviewOpen] = useState(false)
	// const [previewImage, setPreviewImage] = useState('')
	// const [previewTitle, setPreviewTitle] = useState('')
	const [form] = Form.useForm()

	// const getBase64 = (file: RcFile): Promise<string> =>
	// 	new Promise((resolve, reject) => {
	// 		const reader = new FileReader()
	// 		reader.readAsDataURL(file)
	// 		reader.onload = () => resolve(reader.result as string)
	// 		reader.onerror = error => reject(error)
	// 	})

	// const handlePreview = async (file: UploadFile) => {
	// 	if (!file.url && !file.preview) {
	// 		file.preview = await getBase64(file.originFileObj as RcFile)
	// 	}

	// 	setPreviewImage(file.url || (file.preview as string))
	// 	setPreviewOpen(true)
	// 	setPreviewTitle(
	// 		file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
	// 	)
	// }

	// const handleCancel = () => setPreviewOpen(false)

	const pageHeaderProps = {
		setIsOpen
	}
	const contentProps = {
		setIsOpen,
		setModel,
		setSpecialFunctions,
		form
	}
	const modelMutationModalProps = {
		isOpen,
		model,
		specialFunctions,
		setIsOpen,
		setModel,
		setSpecialFunctions,
		form
	}
	const modelDeleteModalProps = {
		isOpen,
		setIsOpen,
		form
	}

	return (
		<>
			<PageHeader {...pageHeaderProps} />
			<Content {...contentProps} />
			<ModelMutationModal {...modelMutationModalProps} />
			<ModelDeleteModal {...modelDeleteModalProps} />

			{/* <Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}>
				<img alt={'preview'} style={{ width: '100%' }} src={previewImage} />
			</Modal> */}
		</>
	)
}

export default Model
