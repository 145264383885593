import { FormInstance } from 'antd'
import { Dispatch, SetStateAction } from 'react'

import Modal from '../../components/Common/Modal'
import SiteMutationModalFormView from '../../components/Site/VAC/SiteMutationModalFormView'

import useReactMutation from '../../hooks/useReactMutation'

interface ISiteMutationModalViewPropsType {
	isOpen: string
	setIsOpen: Dispatch<SetStateAction<string>>
	form: FormInstance<any>
}

interface IModalProps {
	size: 'small' | 'large'
	isOpen: boolean
	title: string
	children?: any
	handleCancel: () => void
	handleOk: () => void
}

interface IVFormProps {
	form: FormInstance<any>
}

function SiteMutationModalView({
	isOpen,
	setIsOpen,
	form
}: ISiteMutationModalViewPropsType) {
	const { sitesMutation, summariesMutation } = useReactMutation()

	const ModalProps: IModalProps = {
		size: 'small',
		isOpen: isOpen.includes('운영그룹'),
		title: isOpen,
		handleCancel: () => {
			form.resetFields()

			setIsOpen('')
		},
		handleOk: () => {
			form
				.validateFields()
				.then(values => {
					const siteId = form.getFieldValue('siteId')
					const summaryId = form.getFieldValue('summaryId')
					const {
						siteName,
						address,
						latitude,
						longitude,
						point,
						robotCount,
						flagshipStore,
						routeDistance,
						routeTime,
						videoCount
					} = values

					if (isOpen === '운영그룹 추가') {
						summariesMutation.createSummary.mutate(
							{
								point,
								robotCount,
								flagshipStore,
								routeDistance,
								routeTime,
								videoCount
							},
							{
								onSuccess(summaryId: any) {
									sitesMutation.createSite.mutate({
										summaryId,
										siteName,
										address,
										latitude,
										longitude
									})
								}
							}
						)
					} else if (isOpen === '운영그룹 설정') {
						sitesMutation.updateSite.mutate({
							siteId,
							siteName,
							address,
							latitude,
							longitude
						})

						summariesMutation.updateSummary.mutate({
							summaryId,
							point,
							robotCount,
							flagshipStore,
							routeDistance,
							routeTime,
							videoCount
						})
					}

					form.resetFields()
					setIsOpen('')
				})
				.catch(info => {
					console.log('Validate Failed:', info)
				})
		}
	}

	const vFormProps: IVFormProps = {
		form
	}

	return (
		<Modal {...ModalProps}>
			<SiteMutationModalFormView {...vFormProps} />
		</Modal>
	)
}

export default SiteMutationModalView
