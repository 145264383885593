import { FormInstance } from 'antd'
import { Dispatch, SetStateAction } from 'react'

import { IRobotModelDataType } from '../../types/interfaces'

import Modal from '../../components/Common/Modal'
import RobotUpdateModalFormView from './VAC/RobotUpdateModalFormView'

import { useQuery } from '@tanstack/react-query'
import { modelsQuery, profilesQuery } from '../../api/queries'
import useReactMutation from '../../hooks/useReactMutation'

interface IProfileMutationModalViewPropsType {
	isOpen: string
	model: IRobotModelDataType | null | undefined
	setIsOpen: Dispatch<SetStateAction<string>>
	setModel: Dispatch<any>
	form: FormInstance<any>
}

interface IModalProps {
	size: 'small' | 'large'
	isOpen: boolean
	title: string
	children?: any
	handleCancel: () => void
	handleOk: () => void
}

interface IVFormProps {}

function RobotUpdateModal({
	isOpen,
	model,
	setIsOpen,
	setModel,
	form
}: IProfileMutationModalViewPropsType) {
	const { isLoading: getProfilesIsLoading, data: profilesData } =
		useQuery(profilesQuery)
	const { isLoading: getModelsIsLoading, data: modelsData } =
		useQuery(modelsQuery)
	const { robotsMutation } = useReactMutation()

	const handleSelectChange = (value: any) => {
		const profileId = value
		const profile = profilesData?.filter(
			(profile: any) => profile.id === profileId
		)[0]
		const model = modelsData?.filter(
			(model: any) => model.id === profile?.modelId
		)[0]

		if (model) {
			setModel(model)
		} else {
			setModel(value)
		}
	}

	const ModalProps: IModalProps = {
		size: 'small',
		isOpen: isOpen === '' ? false : true,
		title: '로봇 설정',
		handleCancel: () => {
			setModel(null)

			form.resetFields()

			setIsOpen('')
		},
		handleOk: () => {
			form
				.validateFields()
				.then(values => {
					const robotId = form.getFieldValue('robotId')
					const { profile_id } = values

					robotsMutation.updateRobot.mutate({
						robotId,
						profile_id
					})

					form.resetFields()
					setIsOpen('')
				})
				.catch(info => {
					console.log('Validate Failed:', info)
				})
		}
	}

	const vFormProps: IVFormProps = {
		isOpen,
		model,
		form,
		profilesData,
		handleSelectChange
	}

	if (getProfilesIsLoading && getModelsIsLoading) {
		return <span>Loading...</span>
	}

	return (
		<Modal {...ModalProps}>
			<RobotUpdateModalFormView {...vFormProps} />
		</Modal>
	)
}

export default RobotUpdateModal
