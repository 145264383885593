import { FormInstance } from 'antd'
import { Dispatch, SetStateAction } from 'react'

import Modal from '../../components/Common/Modal'
import LocationDeleteModalView from './VAC/LocationDeleteModalView'

import useReactMutation from '../../hooks/useReactMutation'

interface IProfileMutationModalViewPropsType {
	isOpen: string
	setIsOpen: Dispatch<SetStateAction<string>>
	form: FormInstance<any>
}

interface IModalProps {
	size: 'small' | 'large'
	isOpen: boolean
	title: string
	okText: string
	children?: any
	handleCancel: () => void
	handleOk: () => void
}

interface IVFormProps {}

function LocationDeleteModal({
	isOpen,
	setIsOpen,
	form
}: IProfileMutationModalViewPropsType) {
	const { locationsMutation } = useReactMutation()

	const ModalProps: IModalProps = {
		size: 'small',
		isOpen: isOpen === '장소 삭제',
		title: isOpen,
		okText: '삭제',
		handleCancel: () => {
			setIsOpen('')
		},
		handleOk: () => {
			form
				.validateFields()
				.then(() => {
					const locationId = form.getFieldValue('locationId')

					locationsMutation.deleteLocation.mutate({
						locationId
					})

					form.resetFields()
					setIsOpen('')
				})
				.catch(info => {
					console.log('Validate Failed:', info)
				})
		}
	}

	const vFormProps: IVFormProps = {
		deleteTarget: {
			locationId: form.getFieldValue('locationId'),
			locationPoint: form.getFieldValue('locationPoint'),
			locationName: form.getFieldValue('locationName')
		}
	}

	return (
		<Modal {...ModalProps}>
			<LocationDeleteModalView {...vFormProps} />
		</Modal>
	)
}

export default LocationDeleteModal
