import request from '../../lib/request'

const profilesRequest = {
	getProfiles: async () => {
		return await request({
			method: 'get',
			url: '/Profiles'
		})
	},

	getProfile: async (variables: any) => {
		const { profileId } = variables

		return await request({
			method: 'get',
			url: `/Profiles/${profileId}`
		})
	},

	create: async (variables: any) => {
		const {
			modelId,
			locationId,
			alias,
			onStateCustomImgUrl,
			offStateCustomImgUrl
		} = variables

		return await request({
			method: 'post',
			url: `/Profiles`,
			data: JSON.stringify({
				model_id: modelId,
				location_id: locationId,
				alias,
				onStateCustomImgUrl,
				offStateCustomImgUrl
			})
		})
	},

	update: async (variables: any) => {
		const {
			profileId,
			modelId,
			locationId,
			alias,
			onStateCustomImgUrl,
			offStateCustomImgUrl
		} = variables

		return await request({
			method: 'patch',
			url: `/Profiles/${profileId}`,
			data: JSON.stringify({
				model_id: modelId,
				location_id: locationId,
				alias,
				onStateCustomImgUrl,
				offStateCustomImgUrl
			})
		})
	},

	delete: async (variables: any) => {
		const { profileId } = variables
		return await request({
			method: 'delete',
			url: `/Profiles/${profileId}`
		})
	}
}

export default profilesRequest
