import request from '../../lib/request'

const robotsRequest = {
	getRobots: async () => {
		return await request({
			method: 'get',
			url: '/Robots'
		})
	},

	getRobot: async (variables: any) => {
		const { robotId } = variables

		return await request({
			method: 'get',
			url: `/Robots/${robotId}`
		})
	},

	update: async (variables: any) => {
		const { robotId, profile_id } = variables

		return await request({
			method: 'patch',
			url: `/Robots/${robotId}`,
			data: JSON.stringify({ profile_id })
		})
	}
}

export default robotsRequest
