import { FormInstance } from 'antd'
import { Dispatch, SetStateAction } from 'react'

import Modal from '../Common/Modal'
import InfoUpdateModalFormView from './VAC/InfoUpdateModalFormView'

import { ACCESS_TOKEN_KEY } from '../../constants/token.constant'
import useReactMutation from '../../hooks/useReactMutation'
import token from '../../lib/token'

interface IProfileModalViewPropsType {
	isOpen: string
	setIsOpen: Dispatch<SetStateAction<string>>
	form: FormInstance<any>
}

interface IModalProps {
	size: 'small' | 'large'
	isOpen: boolean
	title: string
	children?: any
	handleCancel: () => void
	handleOk: () => void
}

interface IVFormProps {
	form: FormInstance<any>
}

function InfoUpdateModal({
	isOpen,
	setIsOpen,
	form
}: IProfileModalViewPropsType) {
	const { adminsMutation } = useReactMutation()

	const ModalProps: IModalProps = {
		size: 'small',
		isOpen: isOpen === '회원정보 변경',
		title: isOpen,
		handleCancel: () => {
			setIsOpen('')
		},
		handleOk: () => {
			form
				.validateFields()
				.then(values => {
					const { adminName, phoneNumber } = values
					const adminId = token.getToken(ACCESS_TOKEN_KEY)

					adminsMutation.updateInfo.mutate({
						adminId,
						adminName,
						phoneNumber
					})

					form.resetFields()
					setIsOpen('')
				})
				.catch(info => {
					console.log('Validate Failed:', info)
				})
		}
	}

	const vInfoUpdateModalFormProps: IVFormProps = {
		form
	}

	return (
		<Modal {...ModalProps}>
			<InfoUpdateModalFormView {...vInfoUpdateModalFormProps} />
		</Modal>
	)
}

export default InfoUpdateModal
