import { FormInstance } from 'antd'
import { Dispatch, SetStateAction } from 'react'

import ModalView from '../Common/Modal'
import PasswordUpdateModalFormView from './VAC/PasswordUpdateModalFormView'

import useReactMutation from '../../hooks/useReactMutation'

interface IProfileModalViewPropsType {
	isOpen: string
	setIsOpen: Dispatch<SetStateAction<string>>
	form: FormInstance<any>
}

interface IModalProps {
	size: 'small' | 'large'
	isOpen: boolean
	title: string
	children?: any
	handleCancel: () => void
	handleOk: () => void
}

interface IVFormProps {
	form: FormInstance<any>
}

function PasswordUpdateModal({
	isOpen,
	setIsOpen,
	form
}: IProfileModalViewPropsType) {
	const { adminsMutation } = useReactMutation()

	const ModalProps: IModalProps = {
		size: 'small',
		isOpen: isOpen === '비밀번호 변경',
		title: isOpen,
		handleCancel: () => {
			setIsOpen('')
		},
		handleOk: () => {
			form
				.validateFields()
				.then(values => {
					const { password } = values

					adminsMutation.updatePassword.mutate({
						adminId: '63894ed9390843c9e175c20b',
						password
						// validatePassword
					})

					form.resetFields()
					setIsOpen('')
				})
				.catch(info => {
					console.log('Validate Failed:', info)
				})
		}
	}

	const vPasswordUpdateModalFormProps: IVFormProps = {
		form
	}

	return (
		<ModalView {...ModalProps}>
			<PasswordUpdateModalFormView {...vPasswordUpdateModalFormProps} />
		</ModalView>
	)
}

export default PasswordUpdateModal
