import { Form } from 'antd'
import { useState } from 'react'

import Content from './Content'
import InfoUpdateModal from './InfoUpdateModal'
import PageHeader from './PageHeader'
import PasswordUpdateModal from './PasswordUpdateModal'

function Admin() {
	const [isOpen, setIsOpen] = useState('')
	const [form] = Form.useForm()

	const contentViewProps = {
		setIsOpen,
		form
	}
	const infoUpdateModalViewProps = {
		isOpen,
		setIsOpen,
		form
	}
	const passwordUpdateModalViewProps = {
		isOpen,
		setIsOpen,
		form
	}

	return (
		<>
			<PageHeader />
			<Content {...contentViewProps} />
			<InfoUpdateModal {...infoUpdateModalViewProps} />
			<PasswordUpdateModal {...passwordUpdateModalViewProps} />
		</>
	)
}

export default Admin
