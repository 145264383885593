import { Button, PageHeader } from 'antd'

function HeaderView() {
	return (
		<PageHeader
			title="서비스명"
			subTitle="관리자"
			extra={[
				<Button key="2" type="text">
					내 정보
				</Button>,
				<Button key="1" type="text" danger>
					로그아웃
				</Button>
			]}
		/>
	)
}

export default HeaderView
