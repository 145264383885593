import { Result } from 'antd'

function VResult() {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%'
			}}>
			<Result title="현재 페이지는 준비중입니다." />
		</div>
	)
}

export default VResult
