import { FormInstance } from 'antd'
import { Dispatch, SetStateAction } from 'react'

import Modal from '../Common/Modal'
import UserDeleteModalView from './VAC/UserDeleteModalView'

import useReactMutation from '../../hooks/useReactMutation'

interface IProfileMutationModalViewPropsType {
	isOpen: string
	setIsOpen: Dispatch<SetStateAction<string>>
	form: FormInstance<any>
}

interface IModalProps {
	size: 'small' | 'large'
	isOpen: boolean
	title: string
	okText: string
	children?: any
	handleCancel: () => void
	handleOk: () => void
}

interface IVFormProps {}

function UserDeleteModal({
	isOpen,
	setIsOpen,
	form
}: IProfileMutationModalViewPropsType) {
	const { usersMutation } = useReactMutation()

	const ModalProps: IModalProps = {
		size: 'small',
		isOpen: isOpen === '사용자 삭제',
		title: isOpen,
		okText: '삭제',
		handleCancel: () => {
			setIsOpen('')
		},
		handleOk: () => {
			form
				.validateFields()
				.then(() => {
					const userId = form.getFieldValue('userId')

					usersMutation.deleteUser.mutate({
						userId
					})

					form.resetFields()
					setIsOpen('')
				})
				.catch(info => {
					console.log('Validate Failed:', info)
				})
		}
	}

	const vFormProps: IVFormProps = {
		deleteTarget: {
			role: form.getFieldValue('role'),
			userName: form.getFieldValue('userName')
		}
	}

	return (
		<Modal {...ModalProps}>
			<UserDeleteModalView {...vFormProps} />
		</Modal>
	)
}

export default UserDeleteModal
