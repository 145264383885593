import {
	Avatar,
	Checkbox,
	Col,
	Form,
	FormInstance,
	Row,
	Typography
} from 'antd'

interface IVFormProps {
	managedRobotIds: string[]
	form: FormInstance<any>
	robotsData: any
	profilesData: any
	locationsData: any
	handleSelectChange: (target: any) => void
}

function UserRobotUpdateModalFormView({
	managedRobotIds,
	form,
	locationsData,
	profilesData,
	robotsData,
	handleSelectChange
}: IVFormProps) {
	return (
		<Form
			form={form}
			layout="horizontal"
			name="user_robot_update_form_in_modal">
			<Form.Item
				className="checkbox_in_userPage managedRobots in-modal"
				name="managedRobotIds"
				style={{ border: 'solid black 1px', paddingLeft: '4.16666667%' }}>
				<Checkbox.Group>
					<Col>
						{robotsData?.map((robot: any) => {
							let profile

							if (robot.profileId === 'null') {
								return null
							} else {
								profile = profilesData?.filter((profile: any) => {
									if (profile.id === robot.profileId) return profile

									return null
								})[0]
							}

							if (!profile) return null

							return (
								<Row key={robot.id}>
									<Checkbox
										value={robot.id}
										onChange={handleSelectChange}
										style={{ alignItems: 'center' }}>
										<Avatar
											size={50}
											style={{
												color: 'white'
											}}
											src={profile?.onStateCustomImgUrl}
										/>
										<Typography.Text style={{ fontSize: '12px' }}>
											{profile?.alias}
										</Typography.Text>
									</Checkbox>
								</Row>
							)
						})}
					</Col>
				</Checkbox.Group>
			</Form.Item>

			<Form.Item
				className={`text_in_userPage in-modal ${
					managedRobotIds.length ? 'actvie-baseline' : ''
				}`}
				label="관리 로봇"
				labelCol={{ offset: 1, span: 4.5 }}
				labelAlign="left"
				colon={true}>
				<Typography.Text style={{ fontSize: '14px' }}>
					{managedRobotIds
						.map(robotId => {
							const robot = robotsData?.filter(
								(robot: any) => robot.id === robotId
							)[0]
							const profile = profilesData?.filter(
								(profile: any) => profile.id === robot?.profileId
							)[0]

							return profile?.alias
						})
						.join(', ')}
				</Typography.Text>
			</Form.Item>

			<Form.Item
				className={`text_in_userPage in-modal ${
					managedRobotIds.length ? 'actvie-baseline' : ''
				}`}
				label="운영 장소"
				labelCol={{ offset: 1, span: 4.5 }}
				labelAlign="left"
				colon={true}>
				<Typography.Text style={{ fontSize: '14px' }}>
					{managedRobotIds
						.map(robotId => {
							const robot = robotsData?.filter(
								(robot: any) => robot.id === robotId
							)[0]
							const profile = profilesData?.filter(
								(profile: any) => profile.id === robot?.profileId
							)[0]
							const location = locationsData?.filter(
								(location: any) => location.id === profile?.locationId
							)[0]

							return location?.locationName
						})
						.join(', ')}
				</Typography.Text>
			</Form.Item>
		</Form>
	)
}

export default UserRobotUpdateModalFormView
