import { Form } from 'antd'
import { useState } from 'react'
import Content from './Content'
import PageHeader from './PageHeader'
import UserCreateModal from './UserCreateModal'
import UserDeleteModal from './UserDeleteModal'
import UserRobotUpdateModal from './UserRobotUpdateModal'
import UserUpdateModal from './UserUpdateModalView'

function User() {
	const [isOpen, setIsOpen] = useState('')
	const [managedRobotIds, setManagedRobotIds] = useState<string[]>([])
	const [form] = Form.useForm()

	const pageHeaderProps = {
		setIsOpen
	}
	const contentProps = {
		setIsOpen,
		setManagedRobotIds,
		form
	}
	const userCreateModalProps = {
		isOpen,
		setIsOpen,
		form
	}
	const userUpdateModalProps = {
		isOpen,
		setIsOpen,
		form
	}
	const userDeleteModalProps = {
		isOpen,
		setIsOpen,
		form
	}
	const userRobotUpdateModalProps = {
		isOpen,
		managedRobotIds,
		setIsOpen,
		setManagedRobotIds,
		form
	}

	return (
		<>
			<PageHeader {...pageHeaderProps} />
			<Content {...contentProps} />
			<UserCreateModal {...userCreateModalProps} />
			<UserUpdateModal {...userUpdateModalProps} />
			<UserDeleteModal {...userDeleteModalProps} />
			<UserRobotUpdateModal {...userRobotUpdateModalProps} />
		</>
	)
}

export default User
