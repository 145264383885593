import { QuestionOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Row, Space, Table, Typography } from 'antd'
import Column from 'antd/lib/table/Column'

function TableView({
	setIsOpen,
	setModel,
	form,
	locationsData,
	modelsData,
	profilesData,
	robotsData,
	sitesData
}: any) {
	return (
		<Table
			dataSource={robotsData}
			pagination={false}
			rowKey={record => record.id}>
			<Column
				title="ID"
				dataIndex="id"
				key="id"
				width="10%"
				align="center"
				render={(_, __, index) => index + 1}
			/>

			<Column
				title="모델"
				dataIndex="model"
				key="model"
				width="25%"
				align="center"
				render={(_, robot: any) => {
					const profile = profilesData?.filter(
						(profile: any) => profile.id === robot?.profileId
					)[0]
					const model = modelsData?.filter(
						(model: any) => model.id === profile?.modelId
					)[0]

					return (
						<Row align="middle" justify="center">
							<Col flex={'100px'}>
								<Avatar
									size={100}
									style={{
										color: 'white'
									}}
									src={
										profile ? (
											profile.onStateCustomImgUrl ? (
												profile.onStateCustomImgUrl
											) : (
												model?.onStateDefaultImgUrl
											)
										) : (
											<QuestionOutlined
												style={{
													color: 'black',
													background: '#fafafa',
													border: '1px solid #f0f0f0',
													padding: '20px',
													borderRadius: '100%'
												}}
											/>
										)
									}
								/>
							</Col>
							<Col flex={'100px'}>
								<Typography.Text style={{ fontSize: '14px' }}>
									{' '}
									{profile ? model?.modelName : 'Unknown'}
								</Typography.Text>
							</Col>
						</Row>
					)
				}}
			/>

			<Column
				title="agent ID"
				dataIndex="agentId"
				key="agentId"
				width="25%"
				align="center"
				render={agentId => (
					<Typography.Text style={{ fontSize: '14px', color: 'green' }}>
						{agentId}
					</Typography.Text>
				)}
			/>

			<Column
				title="프로필"
				dataIndex="profileId"
				key="profileId"
				width="25%"
				align="center"
				render={profileId => {
					const profile = profilesData?.filter(
						(profile: any) => profile.id === profileId
					)[0]
					const location = locationsData?.filter(
						(location: any) => location.id === profile?.locationId
					)[0]
					const site = sitesData?.filter(
						(site: any) => site.id === location?.siteId
					)[0]

					if (location && site) {
						return (
							<Space direction="vertical" size={1}>
								<Typography.Text
									type="secondary"
									style={{ fontSize: '12px', textAlign: 'left' }}>
									{site.siteName}
								</Typography.Text>

								<Typography.Text
									style={{
										fontSize: '14px'
									}}>
									{location.locationName}
								</Typography.Text>
							</Space>
						)
					} else {
						return '-'
					}
				}}
			/>

			<Column
				title="설정"
				key="action"
				width="15%"
				align="center"
				render={robot => {
					const profile = profilesData?.filter(
						(profile: any) => profile.id === robot?.profileId
					)[0]
					const model = modelsData?.filter(
						(model: any) => model.id === profile?.modelId
					)[0]

					return (
						<Button
							type="text"
							onClick={() => {
								setModel(model)

								form.setFieldsValue({
									robotId: robot?.id,
									profile_id: profile?.id ? profile.id : ''
								})

								setIsOpen(robot?.agentId)
							}}
							style={{ color: 'blue', fontSize: '16px' }}>
							설정
						</Button>
					)
				}}
			/>
		</Table>
	)
}

export default TableView
