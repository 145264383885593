import request from '../../lib/request'

const sitesRequest = {
	getSites: async () => {
		return await request({
			method: 'get',
			url: '/Sites'
		})
	},

	getSite: async (variables: any) => {
		const { siteId } = variables

		return await request({
			method: 'get',
			url: `/Sites/${siteId}`
		})
	},

	create: async (variables: any) => {
		const { summaryId, siteName, address, latitude, longitude } = variables

		return await request({
			method: 'post',
			url: `/Sites`,
			data: JSON.stringify({
				summaries_id: summaryId,
				siteName,
				address,
				latitude,
				longitude,
				type: 'site'
			})
		})
	},

	update: async (variables: any) => {
		const { siteId, siteName, address, latitude, longitude } = variables

		return await request({
			method: 'patch',
			url: `/Sites/${siteId}`,
			data: JSON.stringify({ siteName, address, latitude, longitude })
		})
	},

	delete: async (variables: any) => {
		const { siteId } = variables

		return await request({
			method: 'delete',
			url: `/Sites/${siteId}`
		})
	}
}

export default sitesRequest
