import { Form } from 'antd'

import ContentView from './VAC/ContentView'

import { useNavigate } from 'react-router-dom'
import { ACCESS_TOKEN_KEY } from '../../constants/token.constant'
import request from '../../lib/request'
import token from '../../lib/token'

function Content() {
	const navigate = useNavigate()
	const [form] = Form.useForm()

	const onFinish = async (values: any) => {
		const { email, password } = values

		try {
			const result = await request<
				any,
				{
					adminId: string
					adminName: string
					email: string
					password: string
					phoneNumber: string
					role: string
					_id: { $oid: string }
				}
			>({
				method: 'post',
				url: `/signin/Admin`,
				data: JSON.stringify({
					email,
					password
				})
			})

			if (result) {
				token.setToken(ACCESS_TOKEN_KEY, result._id.$oid)
				navigate('/admin')
			}
		} catch (error) {
			throw Error('이메일 혹은 비밀번호를 확인해주세요.')
		}
	}

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo)
	}

	const ContentViewProps = {
		onFinish,
		onFinishFailed,
		form
	}

	return <ContentView {...ContentViewProps} />
}

export default Content
