import { FormInstance } from 'antd'
import { Dispatch, SetStateAction } from 'react'

import ContentView from './VAC/ContentView'
import TableView from './VAC/TableView'

import { useQueries } from '@tanstack/react-query'
import {
	locationsQuery,
	profilesQuery,
	sitesQuery,
	usersQuery
} from '../../api/queries'

interface IContentViewPropsType {
	setIsOpen: Dispatch<SetStateAction<string>>
	setManagedRobotIds: Dispatch<React.SetStateAction<string[]>>
	form: FormInstance<any>
}

function Content({
	setIsOpen,
	setManagedRobotIds,
	form
}: IContentViewPropsType) {
	const queriesResult = useQueries({
		queries: [locationsQuery, profilesQuery, sitesQuery, usersQuery]
	})
	const [getLocationsQuery, getProfilesQuery, getSitesQuery, getUsersQuery] =
		queriesResult

	const vTableProps = {
		setIsOpen,
		setManagedRobotIds,
		form,
		locationsData: getLocationsQuery.data,
		profilesData: getProfilesQuery.data,
		sitesData: getSitesQuery.data,
		usersData: getUsersQuery.data
	}

	if (queriesResult.some(queries => queries.isLoading)) {
		return <span>Loading...</span>
	}

	return (
		<ContentView>
			<TableView {...vTableProps} />
		</ContentView>
	)
}

export default Content
