import { Button, Form, Input, Layout } from 'antd'

function ContentView({ onFinish, onFinishFailed, form }: any) {
	return (
		<Layout.Content
			style={{
				paddingTop: '20px',
				margin: 0,
				borderTop: '1px solid rgba(0, 0, 0, 0.06)',
				borderBottom: '1px solid rgba(0, 0, 0, 0.06)'
			}}>
			<Form
				labelCol={{
					span: 4
				}}
				form={form}
				layout="horizontal"
				name="form_in_modal"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				style={{
					width: '430px'
				}}>
				<Form.Item name="email" label="이메일" labelAlign="left" colon={false}>
					<Input style={{ width: '100%', height: '50px' }} />
				</Form.Item>

				<Form.Item
					name="password"
					label="비밀번호"
					labelAlign="left"
					colon={false}>
					<Input.Password style={{ width: '100%', height: '50px' }} />
				</Form.Item>

				<Form.Item colon={false} wrapperCol={{ offset: 0, sm: { offset: 4 } }}>
					<Button
						type="primary"
						htmlType="submit"
						style={{
							width: '100%',
							height: '50px',
							fontSize: '13px'
						}}>
						로그인
					</Button>
				</Form.Item>
			</Form>
		</Layout.Content>
	)
}

export default ContentView
