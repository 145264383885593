import { Descriptions, Space, Typography } from 'antd'

function DescriptionsView({
	admin,
	handleInfoUpdateModalOpen,
	handlePasswordUpdateModalOpen
}: any) {
	return (
		<Descriptions bordered column={1}>
			<Descriptions.Item label="아이디" labelStyle={{ width: '150px' }}>
				{admin?.email}
			</Descriptions.Item>
			<Descriptions.Item label="비밀번호" labelStyle={{ width: '150px' }}>
				<Typography.Text
					onClick={handlePasswordUpdateModalOpen}
					style={{ color: 'blue' }}>
					변경
				</Typography.Text>
			</Descriptions.Item>
			<Descriptions.Item label="이름" labelStyle={{ width: '150px' }}>
				<Space size={60}>
					{admin?.adminName}
					<Typography.Text
						onClick={handleInfoUpdateModalOpen}
						style={{ color: 'blue' }}>
						변경
					</Typography.Text>
				</Space>
			</Descriptions.Item>
			<Descriptions.Item label="연락처" labelStyle={{ width: '150px' }}>
				{admin?.phoneNumber}
			</Descriptions.Item>
			<Descriptions.Item label="관리자 등급" labelStyle={{ width: '150px' }}>
				{admin?.role}
			</Descriptions.Item>
		</Descriptions>
	)
}

export default DescriptionsView
