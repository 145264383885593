import { FormInstance } from 'antd'
import { Dispatch, SetStateAction } from 'react'

import Modal from '../Common/Modal'
import UserCreateModalFormView from './VAC/UserCreateModalFormView'

import useReactMutation from '../../hooks/useReactMutation'

interface IProfileMutationModalViewPropsType {
	isOpen: string
	setIsOpen: Dispatch<SetStateAction<string>>
	form: FormInstance<any>
}

interface IModalProps {
	size: 'small' | 'large'
	isOpen: boolean
	title: string
	children?: any
	handleCancel: () => void
	handleOk: () => void
}

interface IVFormProps {
	form: FormInstance<any>
}

function UserCreateModal({
	isOpen,
	setIsOpen,
	form
}: IProfileMutationModalViewPropsType) {
	const { usersMutation } = useReactMutation()

	const ModalProps: IModalProps = {
		size: 'small',
		isOpen: isOpen === '회원 등록',
		title: isOpen,
		handleCancel: () => {
			setIsOpen('')
		},
		handleOk: () => {
			form
				.validateFields()
				.then(values => {
					const { email, password, role, userName, phoneNumber } = values

					usersMutation.createUser.mutate({
						email,
						password,
						role,
						userName,
						phoneNumber
					})

					form.resetFields()
					setIsOpen('')
				})
				.catch(info => {
					console.log('Validate Failed:', info)
				})
		}
	}

	const vFormProps: IVFormProps = {
		form
	}

	return (
		<Modal {...ModalProps}>
			<UserCreateModalFormView {...vFormProps} />
		</Modal>
	)
}

export default UserCreateModal
