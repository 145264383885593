import { FormInstance } from 'antd'
import { Dispatch, SetStateAction } from 'react'

import {
	IRobotModelDataType,
	IRobotProfileDataType
} from '../../types/interfaces'

import ContentView from './VAC/ContentView'
import TableView from './VAC/TableView'

import { useQueries } from '@tanstack/react-query'
import {
	locationsQuery,
	modelsQuery,
	profilesQuery,
	sitesQuery
} from '../../api/queries'

interface IContentViewPropsType {
	setProfile: Dispatch<React.SetStateAction<IRobotProfileDataType | null>>
	setModel: Dispatch<React.SetStateAction<IRobotModelDataType | null>>
	setIsOpen: Dispatch<SetStateAction<string>>
	form: FormInstance<any>
}

function Content({
	setProfile,
	setModel,
	setIsOpen,
	form
}: IContentViewPropsType) {
	const queriesResult = useQueries({
		queries: [locationsQuery, modelsQuery, profilesQuery, sitesQuery]
	})
	const [getLocationsQuery, getModelsQuery, getProfilesQuery, getSitesQuery] =
		queriesResult

	const vTableProps = {
		setProfile,
		setModel,
		setIsOpen,
		form,
		locationsData: getLocationsQuery.data,
		modelsData: getModelsQuery.data,
		profilesData: getProfilesQuery.data,
		sitesData: getSitesQuery.data
	}

	if (queriesResult.some(queries => queries.isLoading)) {
		return <span>Loading...</span>
	}

	return (
		<ContentView>
			<TableView {...vTableProps} />
		</ContentView>
	)
}

export default Content
