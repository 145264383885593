import { Modal as ModalView } from 'antd'

interface IModalProps {
	size: 'small' | 'large'
	isOpen: boolean
	title: string
	okText?: string
	children: React.ReactNode
	handleCancel: () => void
	handleOk: () => void
}

function Modal({
	size,
	isOpen,
	title,
	okText,
	handleCancel,
	handleOk,
	children
}: IModalProps) {
	const sizes = {
		small: 430,
		large: 760
	}

	return (
		<ModalView
			forceRender
			open={isOpen}
			title={title}
			cancelText="닫기"
			okText={okText ? okText : '저장'}
			onCancel={handleCancel}
			onOk={handleOk}
			cancelButtonProps={{ ghost: false }}
			width={sizes[size]}
			centered={true}>
			{children}
		</ModalView>
	)
}

export default Modal
