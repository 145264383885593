import { FormInstance } from 'antd'
import { Dispatch, SetStateAction } from 'react'

import Modal from '../../components/Common/Modal'
import ProfileDeleteModalView from './VAC/ProfileDeleteModalView'

import useReactMutation from '../../hooks/useReactMutation'

interface IProfileMutationModalViewPropsType {
	isOpen: string
	setIsOpen: Dispatch<SetStateAction<string>>
	form: FormInstance<any>
}

interface IModalProps {
	size: 'small' | 'large'
	isOpen: boolean
	title: string
	okText: string
	children?: any
	handleCancel: () => void
	handleOk: () => void
}

interface IVFormProps {}

function ProfileDeleteModal({
	isOpen,
	setIsOpen,
	form
}: IProfileMutationModalViewPropsType) {
	const { profilesMutation } = useReactMutation()

	const ModalProps: IModalProps = {
		size: 'small',
		isOpen: isOpen === '로봇 프로필 삭제',
		title: isOpen,
		okText: '삭제',
		handleCancel: () => {
			setIsOpen('')
		},
		handleOk: () => {
			form
				.validateFields()
				.then(() => {
					const profileId = form.getFieldValue('profileId')

					profilesMutation.deleteProfile.mutate({
						profileId
					})

					form.resetFields()
					setIsOpen('')
				})
				.catch(info => {
					console.log('Validate Failed:', info)
				})
		}
	}

	const vFormProps: IVFormProps = {
		deleteTarget: {
			modelName: form.getFieldValue('modelName'),
			alias: form.getFieldValue('alias')
		}
	}

	return (
		<Modal {...ModalProps}>
			<ProfileDeleteModalView {...vFormProps} />
		</Modal>
	)
}

export default ProfileDeleteModal
