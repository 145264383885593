import { Route, Routes } from 'react-router-dom'

import VResult from '../../components/VResult'
import LayoutOutlet from '../LayoutOutlet/LayoutOutlet'

import {
	AdminPage,
	LoginPage,
	ModelPage,
	ProfilePage,
	RobotPage,
	SitePage,
	UserPage
} from '../../pages'
import AuthValidator from '../Common/AuthValidator'

function Router() {
	// const AuthLayoutOutlet = AuthHoc(LayoutOutlet)

	return (
		<Routes>
			<Route
				path="/"
				element={
					<AuthValidator>
						<LayoutOutlet />
					</AuthValidator>
				}>
				<Route path="/admin" element={<AdminPage />} />
				<Route path="/users" element={<UserPage />} />
				<Route path="/sites" element={<SitePage />} />
				<Route path="/robots" element={<RobotPage />} />
				<Route path="/profiles" element={<ProfilePage />} />
				<Route path="/models" element={<ModelPage />} />
				<Route path="*" element={<VResult />} />
			</Route>
			<Route path="/login" element={<LoginPage />} />
		</Routes>
	)
}

export default Router
