import { FileImageOutlined } from '@ant-design/icons'
import {
	Avatar,
	Form,
	Image,
	Input,
	Select,
	Space,
	TreeSelect,
	Typography,
	Upload
} from 'antd'

function VProfileMutationModalForm({
	model,
	profile,
	form,
	robotStateImg,
	modelsData,
	sitesData,
	handleSelectChange,
	handleUploadOnStateCustomImg,
	handleUploadOffStateCustomImg
}: any) {
	const { TreeNode } = TreeSelect

	return (
		<Form form={form} layout="horizontal" name="form_in_modal">
			<Form.Item
				className="select_in_robotProfile model in-modal"
				name="modelId"
				label="모델"
				labelCol={{ offset: 1, span: 5 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Select
					showArrow
					allowClear
					bordered
					placeholder={
						model ? (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									height: '100%'
								}}>
								<Avatar
									size={50}
									style={{
										color: 'white'
									}}
									src={model?.onStateDefaultImgUrl}
								/>
								<Typography.Text style={{ fontSize: '12px' }}>
									{model?.modelName}
								</Typography.Text>
							</div>
						) : null
					}
					onChange={handleSelectChange}
					style={{ width: '250px', height: '50px' }}>
					{modelsData?.map((robot: any) => {
						const { id, modelName, onStateDefaultImgUrl } = robot

						return (
							<Select.Option key={id} value={id}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										height: '100%'
									}}>
									<Avatar
										size={50}
										style={{
											color: 'white'
										}}
										src={onStateDefaultImgUrl}
									/>
									<Typography.Text style={{ fontSize: '12px' }}>
										{modelName}
									</Typography.Text>
								</div>
							</Select.Option>
						)
					})}
				</Select>
			</Form.Item>

			<Form.Item
				className="in-modal"
				name="alias"
				label="별칭"
				labelCol={{ offset: 1, span: 5 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Input style={{ width: '250px', height: '50px' }} />
			</Form.Item>

			<Form.Item
				className="in-modal"
				label="모델사진"
				labelCol={{ offset: 1, span: 5 }}
				labelAlign="left"
				colon={false}
				style={{ height: '100%' }}>
				<Form.List
					name="robotStateImg"
					initialValue={[{ onStateCustomImg: '', offStateCustomImg: '' }]}>
					{fields =>
						fields.map(field => {
							return (
								<Space
									className="upload_in_robotProfile stateImg"
									key={field.key}
									direction="horizontal">
									<Form.Item style={{ height: '100%' }}>
										<Space direction="vertical">
											<Typography.Text
												strong
												style={{
													fontSize: '14px'
												}}>
												ON 상태
											</Typography.Text>

											<Upload
												accept="image/png, image/jpeg"
												listType="picture-card"
												maxCount={1}
												showUploadList={{
													showPreviewIcon: false
												}}
												onChange={handleUploadOnStateCustomImg}
												action="https://43.201.107.24:5000/upload">
												{robotStateImg.onStateCustomImgUrl ? null : model ? (
													<Image
														preview={false}
														src={
															profile
																? profile?.onStateCustomImgUrl
																: model.onStateDefaultImgUrl
														}
													/>
												) : (
													<div>
														<FileImageOutlined style={{ fontSize: '30px' }} />
														<div
															style={{
																marginTop: 8
															}}>
															선택
														</div>
													</div>
												)}
											</Upload>
										</Space>
									</Form.Item>

									<Form.Item style={{ height: '100%' }}>
										<Space direction="vertical">
											<Typography.Text
												style={{
													fontSize: '14px',
													fontWeight: 600
												}}>
												OFF 상태
											</Typography.Text>

											<Upload
												accept="image/png, image/jpeg"
												listType="picture-card"
												maxCount={1}
												showUploadList={{
													showPreviewIcon: false
												}}
												onChange={handleUploadOffStateCustomImg}
												action="https://43.201.107.24:5000/upload">
												{robotStateImg.offStateCustomImgUrl ? null : model ? (
													<Image
														preview={false}
														src={
															profile
																? profile?.offStateCustomImgUrl
																: model.offStateDefaultImgUrl
														}
													/>
												) : (
													<div>
														<FileImageOutlined style={{ fontSize: '30px' }} />
														<div
															style={{
																marginTop: 8
															}}>
															선택
														</div>
													</div>
												)}
											</Upload>
										</Space>
									</Form.Item>
								</Space>
							)
						})
					}
				</Form.List>
			</Form.Item>

			<Form.Item
				className="select_in_robotProfile site in-modal"
				name="locationId"
				label="운영구역"
				labelCol={{ offset: 1, span: 5 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<TreeSelect
					allowClear
					treeDefaultExpandAll
					showArrow
					switcherIcon={
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							width={20}
							height={20}>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
							/>
						</svg>
					}
					treeLine={{
						showLeafIcon: (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="-4.5 2 24 26"
								strokeWidth={1.5}
								stroke="currentColor"
								width={16}
								height={16}>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
								/>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
								/>
							</svg>
						)
					}}
					style={{ width: '250px', height: '50px' }}>
					{sitesData?.map((site: any) => {
						const { siteName, locations } = site

						return (
							<TreeNode
								key={siteName}
								selectable={false}
								value={siteName}
								title={siteName}
								isLeaf={false}
								style={{ fontSize: '12px' }}>
								{locations?.map((location: any) => {
									const { id, locationName } = location

									return (
										<TreeNode
											key={id}
											value={id}
											title={locationName}
											style={{ fontSize: '12px' }}
										/>
									)
								})}
							</TreeNode>
						)
					})}
				</TreeSelect>
			</Form.Item>
		</Form>
	)
}

export default VProfileMutationModalForm
