import { FormInstance } from 'antd'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import ContentView from './VAC/ContentView'
import TableView from './VAC/TableView'

import { useQuery } from '@tanstack/react-query'
import { sitesQuery, summariesQuery } from '../../api/queries'

interface IContentViewPropsType {
	setIsOpen: Dispatch<SetStateAction<string>>
	form: FormInstance<any>
}

function Content({ setIsOpen, form }: IContentViewPropsType) {
	const { isLoading: getSitesIsLoading, data: sitesData } = useQuery(sitesQuery)
	const { isLoading: getSummariesIsLoading, data: summariesData } =
		useQuery(summariesQuery)
	const [sitesServiceData, setSitesServiceData] = useState<any[]>([])

	useEffect(() => {
		// 데이터 가공, request => service
		const newSites: any[] = []

		if (sitesData instanceof Array) {
			sitesData?.forEach(data => {
				newSites.push(data)

				if (data.locations?.length) {
					newSites.push(...data.locations)
				}
			})
		}

		setSitesServiceData(newSites)
	}, [sitesData])

	const vTableProps = {
		setIsOpen,
		form,
		sitesServiceData,
		summariesData
	}

	if (getSitesIsLoading && getSummariesIsLoading) {
		return <span>Loading...</span>
	}

	return (
		<ContentView>
			<TableView {...vTableProps} />
		</ContentView>
	)
}

export default Content
