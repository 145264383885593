import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ACCESS_TOKEN_KEY } from '../../constants/token.constant'
import token from '../../lib/token'

export interface IChildrenProps {
	children?: React.ReactNode
}

function AuthValidator({ children }: IChildrenProps) {
	const navigate = useNavigate()

	useEffect(() => {
		if (token.getToken(ACCESS_TOKEN_KEY) === null) {
			navigate('/login')
		}
	}, [navigate])

	return <>{children}</>
}

export default AuthValidator
