import { Form } from 'antd'
import { useState } from 'react'

import { IRobotModelDataType } from '../../types/interfaces'
import Content from './Content'
import PageHeader from './PageHeader'
import RobotUpdateModal from './RobotUpdateModal'

function Robot() {
	const [isOpen, setIsOpen] = useState('')
	const [model, setModel] = useState<IRobotModelDataType | null>()
	const [form] = Form.useForm()

	const pageHeaderProps = {}
	const contentProps = {
		setIsOpen,
		setModel,
		form
	}
	const robotUpdateModalProps = {
		isOpen,
		model,
		setIsOpen,
		setModel,
		form
	}

	return (
		<>
			<PageHeader {...pageHeaderProps} />
			<Content {...contentProps} />
			<RobotUpdateModal {...robotUpdateModalProps} />
		</>
	)
}

export default Robot
