import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'
import SiderMenu from './SiderMenu'
import HeaderView from './Views/HeaderView'

function LayoutOutlet() {
	return (
		<Layout>
			<HeaderView />
			<Layout>
				<SiderMenu />
				<Layout style={{ padding: '0 24px 24px' }}>
					<Outlet />
				</Layout>
			</Layout>
		</Layout>
	)
}

export default LayoutOutlet
