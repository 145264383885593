import { Divider, Form, Input, Space, Typography } from 'antd'

function SiteMutationModalFormView({ form }: any) {
	return (
		<Form form={form} layout="horizontal" name="form_in_modal">
			<Form.Item
				className="in-modal"
				name="siteName"
				label="이름"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Input style={{ width: '250px', height: '50px' }} />
			</Form.Item>

			<Form.Item
				className="in-modal"
				name="address"
				label="주소"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Input style={{ width: '250px', height: '50px' }} />
			</Form.Item>

			<Form.Item
				className="in-modal"
				label="좌표"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{ height: '100%' }}>
				<Space className="input_in_site coordinates">
					<Form.Item name="latitude" noStyle>
						<Input style={{ width: '121px', height: '50px' }} />
					</Form.Item>
					<Form.Item name="longitude" noStyle>
						<Input style={{ width: '121px', height: '50px' }} />
					</Form.Item>
				</Space>
			</Form.Item>

			<Divider
				orientation="left"
				orientationMargin={0}
				plain
				style={{ fontSize: '11px' }}>
				요약정보
			</Divider>

			<Form.Item
				className="in-modal"
				name="point"
				label="운행구역"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Input style={{ width: '250px', height: '50px' }} />
			</Form.Item>

			<Form.Item
				className="in-modal"
				label="운영로봇"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Form.Item name="robotCount" noStyle>
					<Input style={{ width: '121px', height: '50px' }} />
				</Form.Item>
				<Typography.Text> 대</Typography.Text>
			</Form.Item>

			<Form.Item
				className="in-modal"
				name="flagshipStore"
				label="대표매장"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Input style={{ width: '250px', height: '50px' }} />
			</Form.Item>

			<Form.Item
				className="in-modal"
				label="경로거리"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Form.Item name="routeDistance" noStyle>
					<Input style={{ width: '121px', height: '50px' }} />
				</Form.Item>
				<Typography.Text> m</Typography.Text>
			</Form.Item>

			<Form.Item
				className="in-modal"
				label="운행소요시간"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Form.Item name="routeTime" noStyle>
					<Input style={{ width: '121px', height: '50px' }} />
				</Form.Item>
				<Typography.Text> min</Typography.Text>
			</Form.Item>

			<Form.Item
				className="in-modal"
				label="등록영상 수"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Form.Item name="videoCount" noStyle>
					<Input style={{ width: '121px', height: '50px' }} />
				</Form.Item>
				<Typography.Text> 개</Typography.Text>
			</Form.Item>
		</Form>
	)
}

export default SiteMutationModalFormView
