import { Dispatch, SetStateAction } from 'react'

import ContentView from './VAC/ContentView'

import { useQuery } from '@tanstack/react-query'
import { FormInstance } from 'antd'
import { adminsQuery } from '../../api/queries'
import { ACCESS_TOKEN_KEY } from '../../constants/token.constant'
import token from '../../lib/token'
import DescriptionsView from './VAC/DescriptionsView'

interface IContentViewPropsType {
	setIsOpen: Dispatch<SetStateAction<string>>
	form: FormInstance<any>
}

function Content({ setIsOpen, form }: IContentViewPropsType) {
	const { isLoading, data: adminsData } = useQuery(adminsQuery)
	const adminId = token.getToken(ACCESS_TOKEN_KEY)

	const vDescriptionsProps = {
		admin: adminsData?.filter((admin: any) => admin.id === adminId)[0],
		handleInfoUpdateModalOpen: () => {
			const admin = adminsData?.filter((admin: any) => admin.id === adminId)[0]

			form.setFieldsValue({
				adminName: admin?.adminName,
				phoneNumber: admin?.phoneNumber
			})

			setIsOpen('회원정보 변경')
		},
		handlePasswordUpdateModalOpen: () => {
			setIsOpen('비밀번호 변경')
		}
	}

	if (isLoading) {
		return <span>Loading...</span>
	}

	return (
		<ContentView>
			<DescriptionsView {...vDescriptionsProps} />
		</ContentView>
	)
}

export default Content
