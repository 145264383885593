import { Avatar, Button, Col, Row, Space, Table, Typography } from 'antd'
import Column from 'antd/lib/table/Column'

function VTable({
	setProfile,
	setModel,
	setIsOpen,
	form,
	locationsData,
	modelsData,
	profilesData,
	sitesData
}: any) {
	return (
		<Table
			dataSource={profilesData}
			pagination={false}
			rowKey={record => record.id}>
			<Column
				title="ID"
				dataIndex="id"
				key="id"
				width="10%"
				align="center"
				render={(_, __, index) => index + 1}
			/>

			<Column
				title="모델"
				dataIndex="model"
				key="model"
				width="25%"
				align="center"
				render={(_, profile: any) => {
					const model = modelsData?.filter(
						(model: any) => model.id === profile?.modelId
					)[0]

					return (
						<Row justify="center" align="middle">
							<Col flex={'100px'}>
								<Avatar
									size={100}
									style={{
										color: 'white'
									}}
									src={
										profile?.onStateCustomImgUrl
											? profile.onStateCustomImgUrl
											: model?.onStateDefaultImgUrl
									}
								/>
							</Col>
							<Col flex={'100px'}>
								<Typography.Text style={{ fontSize: '14px' }}>
									{model?.modelName}
								</Typography.Text>
							</Col>
						</Row>
					)
				}}
			/>

			<Column
				title="별칭"
				dataIndex="alias"
				key="alias"
				width="25%"
				align="center"
			/>

			<Column
				title="운영구역"
				dataIndex="site"
				key="site"
				width="25%"
				align="center"
				render={(_, profile: any) => {
					const location = locationsData?.filter(
						(location: any) => location.id === profile?.locationId
					)[0]
					const site = sitesData?.filter(
						(site: any) => site.id === location?.siteId
					)[0]

					if (location && site) {
						return (
							<Space direction="vertical" size={1}>
								<Typography.Text
									type="secondary"
									style={{ fontSize: '12px', textAlign: 'left' }}>
									{location.locationName}
								</Typography.Text>

								<Typography.Text
									style={{
										fontSize: '14px'
									}}>
									{site.siteName}
								</Typography.Text>
							</Space>
						)
					} else {
						return ''
					}
				}}
			/>

			<Column
				title="설정"
				key="action"
				width="15%"
				align="center"
				render={profile => {
					const {
						id: profileId,
						alias,
						locationId,
						modelId,
						onStateCustomImgUrl,
						offStateCustomImgUrl
					} = profile

					const model = modelsData?.filter(
						(model: any) => model.id === profile?.modelId
					)[0]

					return (
						<>
							<Button
								type="text"
								onClick={() => {
									setProfile(profile)
									setModel(model)

									form.setFieldsValue({
										profileId,
										modelId,
										locationId,
										alias,
										onStateCustomImgUrl,
										offStateCustomImgUrl
									})

									setIsOpen('로봇 프로필 설정')
								}}
								style={{ color: 'blue', fontSize: '16px' }}>
								설정
							</Button>
							<Button
								type="text"
								onClick={() => {
									form.setFieldsValue({
										profileId,
										modelName: model?.modelName,
										alias
									})

									setIsOpen('로봇 프로필 삭제')
								}}
								style={{ color: 'red', fontSize: '16px' }}>
								삭제
							</Button>
						</>
					)
				}}
			/>
		</Table>
	)
}

export default VTable
