import { Layout, Menu, MenuProps } from 'antd'

type MenuItem = Required<MenuProps>['items'][number]

interface IvSiderMenu {
	keys: string
	items: MenuItem[]
}

function SiderMenuView({ keys, items }: IvSiderMenu) {
	return (
		<Layout.Sider width={200}>
			<Menu
				mode="inline"
				defaultOpenKeys={['sub1', 'sub2', 'sub3', '']}
				selectedKeys={[keys]}
				items={items}
				style={{ height: '100%', borderRight: 0 }}
			/>
		</Layout.Sider>
	)
}

export default SiderMenuView
