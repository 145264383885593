import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { HashRouter } from 'react-router-dom'
import App from './App'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 60 * 5 * 1000,
			refetchOnReconnect: 'always'
		}
	}
})

root.render(
	<React.StrictMode>
		<HashRouter>
			<QueryClientProvider client={queryClient}>
				<App />
			</QueryClientProvider>
		</HashRouter>
	</React.StrictMode>
)
