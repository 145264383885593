import { Avatar, Badge, Button, Col, Row, Space, Table, Typography } from 'antd'
import Column from 'antd/lib/table/Column'

function TableView({
	setIsOpen,
	setModel,
	setSpecialFunctions,
	form,
	modelsData
}: any) {
	return (
		<Table
			dataSource={modelsData}
			pagination={false}
			rowKey={record => record.id}>
			<Column
				title="ID"
				dataIndex="id"
				key="id"
				width="10%"
				align="center"
				render={(_, __, index) => index + 1}
			/>

			<Column
				title="모델"
				dataIndex="modelName"
				key="modelName"
				width="25%"
				align="center"
				render={(modelName, model: any) => {
					return (
						<Row justify="center" align="middle">
							<Col flex={'100px'}>
								<Avatar
									size={100}
									style={{
										color: 'white'
									}}
									src={model.onStateDefaultImgUrl}
								/>
							</Col>
							<Col flex={'100px'}>
								<Typography.Text style={{ fontSize: '14px' }}>
									{modelName}
								</Typography.Text>
							</Col>
						</Row>
					)
				}}
			/>

			<Column
				title="기능"
				dataIndex="specialFunctions"
				key="specialFunctions"
				width="45%"
				align="center"
				render={specialFunctions => {
					if (specialFunctions?.length) {
						return (
							<Space
								direction="horizontal"
								wrap
								style={{ justifyContent: 'center' }}>
								{specialFunctions.split(', ').map((specialFunction: string) => {
									const colors: { [index: string]: string } = {
										컨시어지: 'orange',
										AUTONOMOUS: 'green',
										노마스크탐지: 'yellow',
										발열탐지: 'red',
										음성대화: 'blue',
										도슨트: 'purple'
									}

									return (
										<Badge
											key={specialFunction}
											color={colors[specialFunction]}
											text={specialFunction}
											style={{
												border: '1px solid black',
												borderRadius: '12px',
												padding: '1px 10px'
											}}
										/>
									)
								})}
							</Space>
						)
					} else {
						return null
					}
				}}
			/>

			<Column
				title="설정"
				key="action"
				width="20%"
				align="center"
				render={model => {
					const {
						id: modelId,
						homepage,
						manufacturer,
						manufacturerContact,
						manufacturingDate,
						modelName,
						onStateDefaultImgUrl,
						offStateDefaultImgUrl,
						supplierContact,
						supplierHomepage,
						supplierName
					} = model

					return (
						<>
							<Button
								type="text"
								onClick={() => {
									setModel(model)
									setSpecialFunctions(model?.specialFunctions)

									form.setFieldsValue({
										modelId,
										homepage,
										manufacturer,
										manufacturerContact,
										manufacturingDate,
										modelName,
										onStateDefaultImgUrl,
										offStateDefaultImgUrl,
										supplierContact,
										supplierHomepage,
										supplierName
									})

									setIsOpen('로봇 모델 설정')
								}}
								style={{ color: 'blue', fontSize: '16px' }}>
								설정
							</Button>
							<Button
								type="text"
								onClick={() => {
									form.setFieldsValue({
										modelId,
										modelName
									})

									setIsOpen('로봇 모델 삭제')
								}}
								style={{ color: 'red', fontSize: '16px' }}>
								삭제
							</Button>
						</>
					)
				}}
			/>
		</Table>
	)
}

export default TableView
