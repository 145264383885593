import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
	adminsRequest,
	locationsRequest,
	modelsRequest,
	profilesRequest,
	robotsRequest,
	sitesRequest,
	summariesRequest,
	usersRequest
} from '../api/request'

function useReactMutation() {
	const queryClient = useQueryClient()
	const adminsMutation = {
		updatePassword: useMutation({
			mutationFn: adminsRequest.updatePassword,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getAdmin'] })
			}
		}),

		updateInfo: useMutation({
			mutationFn: adminsRequest.updateInfo,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getAdmin'] })
			}
		})
	}

	const locationsMutation = {
		createLocation: useMutation({
			mutationFn: locationsRequest.create,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getSites'] })
			}
		}),
		updateLocation: useMutation({
			mutationFn: locationsRequest.update,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getSites'] })
			}
		}),
		deleteLocation: useMutation({
			mutationFn: locationsRequest.delete,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getSites'] })
			}
		})
	}

	const modelsMutation = {
		createModel: useMutation({
			mutationFn: modelsRequest.create,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getModels'] })
			}
		}),
		updateModel: useMutation({
			mutationFn: modelsRequest.update,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getModels'] })
			}
		}),
		deleteModel: useMutation({
			mutationFn: modelsRequest.delete,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getModels'] })
			}
		})
	}

	const profilesMutation = {
		createProfile: useMutation({
			mutationFn: profilesRequest.create,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getProfiles'] })
			}
		}),
		updateProfile: useMutation({
			mutationFn: profilesRequest.update,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getProfiles'] })
			}
		}),
		deleteProfile: useMutation({
			mutationFn: profilesRequest.delete,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getProfiles'] })
			}
		})
	}

	const robotsMutation = {
		updateRobot: useMutation({
			mutationFn: robotsRequest.update,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getRobots'] })
			}
		})
	}

	const sitesMutation = {
		createSite: useMutation({
			mutationFn: sitesRequest.create,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getSites'] })
			}
		}),
		updateSite: useMutation({
			mutationFn: sitesRequest.update,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getSites'] })
			}
		}),
		deleteSite: useMutation({
			mutationFn: sitesRequest.delete,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getSites'] })
			}
		})
	}

	const summariesMutation = {
		createSummary: useMutation({
			mutationFn: summariesRequest.create,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getSummaries'] })
			}
		}),
		updateSummary: useMutation({
			mutationFn: summariesRequest.update,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getSummaries'] })
			}
		}),
		deleteSummary: useMutation({
			mutationFn: summariesRequest.delete,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getSummaries'] })
			}
		})
	}

	const usersMutation = {
		createUser: useMutation({
			mutationFn: usersRequest.create,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getUsers'] })
			}
		}),
		updateUser: useMutation({
			mutationFn: usersRequest.update,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getUsers'] })
			}
		}),
		deleteUser: useMutation({
			mutationFn: usersRequest.delete,
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries({ queryKey: ['getUsers'] })
			}
		})
	}

	return {
		adminsMutation,
		locationsMutation,
		modelsMutation,
		profilesMutation,
		robotsMutation,
		sitesMutation,
		summariesMutation,
		usersMutation
	}
}

export default useReactMutation
