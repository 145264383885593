import { Dispatch, SetStateAction } from 'react'

import PageHeaderView from './VAC/PageHeaderView'

interface IPageHeaderViewPropsType {
	setIsOpen: Dispatch<SetStateAction<string>>
}

function PageHeader({ setIsOpen }: IPageHeaderViewPropsType) {
	const PageHeaderViewProps = {
		handleProfileCreateModalClick: () => {
			setIsOpen('로봇 프로필 추가')
		}
	}

	return <PageHeaderView {...PageHeaderViewProps} />
}

export default PageHeader
