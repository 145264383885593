import { Form } from 'antd'
import { useState } from 'react'

import {
	IRobotModelDataType,
	IRobotProfileDataType
} from '../../types/interfaces'
import Content from './Content'
import PageHeader from './PageHeader'
import ProfileDeleteModal from './ProfileDeleteModal'
import ProfileMutationModal from './ProfileMutationModal'

function Profile() {
	const [isOpen, setIsOpen] = useState('')
	const [model, setModel] = useState<IRobotModelDataType | null>(null)
	const [profile, setProfile] = useState<IRobotProfileDataType | null>(null)
	const [form] = Form.useForm()

	const pageHeaderProps = {
		setIsOpen
	}
	const contentProps = {
		setIsOpen,
		setModel,
		setProfile,
		form
	}
	const profileMutationModalProps = {
		isOpen,
		model,
		profile,
		setIsOpen,
		setModel,
		setProfile,
		form
	}
	const profileDeleteModalProps = {
		isOpen,
		setIsOpen,
		form
	}

	return (
		<>
			<PageHeader {...pageHeaderProps} />
			<Content {...contentProps} />
			<ProfileMutationModal {...profileMutationModalProps} />
			<ProfileDeleteModal {...profileDeleteModalProps} />
		</>
	)
}

export default Profile
