import {
	adminsRequest,
	locationsRequest,
	modelsRequest,
	profilesRequest,
	robotsRequest,
	sitesRequest,
	summariesRequest,
	usersRequest
} from '../request'

const adminsQuery = {
	queryKey: ['getAdmin'],
	queryFn: adminsRequest.getAdmin,
	select: (admins: any) => {
		const parse = admins.map((data: any) => {
			return {
				adminName: data.adminName,
				email: data.email,
				phoneNumber: data.phoneNumber,
				role: data.role,
				id: data._id.$oid
			}
		})

		return parse
	}
}

const locationsQuery = {
	queryKey: ['getLocations'],
	queryFn: locationsRequest.getLocations,
	select: (locations: any) => {
		const parse = locations.map((data: any) => {
			return {
				locationName: data.locationName,
				locationPoint: data.locationPoint,
				siteId: data.site_id,
				type: data.type,
				id: data._id.$oid
			}
		})

		return parse
	}
}

const modelsQuery = {
	queryKey: ['getModels'],
	queryFn: modelsRequest.getModels,
	select: (models: any) => {
		const parse = models.map((data: any) => {
			return {
				homepage: data.homepage,
				manufacturer: data.manufacturer,
				manufacturerContact: data.manufacturerContact,
				manufacturingDate: data.manufacturingDate,
				modelName: data.modelName,
				offStateDefaultImgUrl: data.offStateDefaultImgUrl,
				onStateDefaultImgUrl: data.onStateDefaultImgUrl,
				specialFunctions: data.specialFunctions,
				supplierContact: data.supplierContact,
				supplierHomepage: data.supplierHomepage,
				supplierName: data.supplierName,
				id: data._id.$oid
			}
		})

		return parse
	}
}

const profilesQuery = {
	queryKey: ['getProfiles'],
	queryFn: profilesRequest.getProfiles,
	select: (profiles: any) => {
		const parse = profiles.map((data: any) => {
			return {
				alias: data.alias,
				locationId: data.location_id,
				modelId: data.model_id,
				offStateCustomImgUrl: data.offStateCustomImgUrl,
				onStateCustomImgUrl: data.onStateCustomImgUrl,
				id: data._id.$oid
			}
		})

		return parse
	}
}

const robotsQuery = {
	queryKey: ['getRobots'],
	queryFn: robotsRequest.getRobots,
	select: (robots: any) => {
		const parse = robots.map((data: any) => {
			return {
				profileId: data.profile_id,
				agentId: data.agentID,
				id: data._id.$oid
			}
		})

		return parse
	}
}

const sitesQuery = {
	queryKey: ['getSites'],
	queryFn: sitesRequest.getSites,
	select: (sites: any) => {
		const parse = sites.map((data: any) => {
			let locations

			if (data.locations.length) {
				locations = data.locations.map((location: any) => {
					const { locationName, locationPoint, type, _id } = location

					return {
						locationName,
						locationPoint,
						type,
						id: _id.$oid
					}
				})
			}

			return {
				address: data.address,
				latitude: data.latitude,
				locations,
				longitude: data.longitude,
				siteName: data.siteName,
				videoCount: data.summaries_id,
				summariesId: data.summaries_id,
				summary: data.summary,
				type: data.type,
				id: data._id.$oid
			}
		})

		return parse
	}
}

const summariesQuery = {
	queryKey: ['getSummaries'],
	queryFn: summariesRequest.getSummaries,
	select: (summaries: any) => {
		const parse = summaries.map((data: any) => {
			return {
				flagshipStore: data.flagshipStore,
				point: data.point,
				robotCount: data.robotCount,
				routeDistance: data.routeDistance,
				routeTime: data.routeTime,
				videoCount: data.videoCount,
				id: data._id.$oid
			}
		})

		return parse
	}
}

const usersQuery = {
	queryKey: ['getUsers'],
	queryFn: usersRequest.getUsers,
	select: (users: any) => {
		const parse = users.map((data: any) => {
			return {
				email: data.email,
				managedRobotIds: data.managedRobotIds,
				managedRobots: data.managedRobots,
				phoneNumber: data.phoneNumber,
				role: data.role,
				userName: data.userName,
				id: data._id.$oid
			}
		})

		return parse
	}
}

export {
	adminsQuery,
	locationsQuery,
	modelsQuery,
	profilesQuery,
	robotsQuery,
	sitesQuery,
	summariesQuery,
	usersQuery
}
