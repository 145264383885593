import { FormInstance } from 'antd'
import { Dispatch, SetStateAction, useState } from 'react'

import { IRobotModelDataType } from '../../types/interfaces'

import Modal from '../../components/Common/Modal'
import useReactMutation from '../../hooks/useReactMutation'
import ProfileMutationModalFormView from './VAC/ModelMutationModalFormView'

interface IProfileMutationModalViewPropsType {
	isOpen: string
	model: IRobotModelDataType | null
	specialFunctions: string
	setIsOpen: Dispatch<SetStateAction<string>>
	setModel: Dispatch<React.SetStateAction<IRobotModelDataType | null>>
	setSpecialFunctions: Dispatch<React.SetStateAction<string>>
	form: FormInstance<any>
}

interface IModalProps {
	size: 'small' | 'large'
	isOpen: boolean
	title: string
	children?: any
	handleCancel: () => void
	handleOk: () => void
}

interface IVFormProps {
	model: IRobotModelDataType | null
	specialFunctions: string
	form: FormInstance<any>
	robotStateImg: { onStateDefaultImgUrl: string; offStateDefaultImgUrl: string }
	handleChange: (tag: string, checked: boolean) => void
	handleUploadOnStateDefaultImg: (fileList: any) => void
	handleUploadOffStateDefaultImg: (fileList: any) => void
}

function ModelMutationModalView({
	isOpen,
	model,
	specialFunctions,
	setIsOpen,
	setModel,
	setSpecialFunctions,
	form
}: IProfileMutationModalViewPropsType) {
	const [robotStateImg, setRobotStateImg] = useState({
		onStateDefaultImgUrl: '',
		offStateDefaultImgUrl: ''
	})
	const { modelsMutation } = useReactMutation()

	const ModalProps: IModalProps = {
		size: 'large',
		isOpen: isOpen === '로봇 모델 등록' || isOpen === '로봇 모델 설정',
		title: isOpen,
		handleCancel: () => {
			setModel(null)
			setSpecialFunctions('')
			setRobotStateImg({
				onStateDefaultImgUrl: '',
				offStateDefaultImgUrl: ''
			})

			form.resetFields()

			setIsOpen('')
		},
		handleOk: () => {
			form
				.validateFields()
				.then(values => {
					const {
						modelId,
						onStateDefaultImgUrl: $onStateDefaultImgUrl,
						offStateDefaultImgUrl: $offStateDefaultImgUrl
					} = form.getFieldsValue(true)

					const {
						homepage,
						manufacturer,
						manufacturerContact,
						manufacturingDate,
						modelName,
						supplierContact,
						supplierHomepage,
						supplierName
					} = values
					const { onStateDefaultImgUrl, offStateDefaultImgUrl } = robotStateImg

					if (isOpen === '로봇 모델 등록') {
						modelsMutation.createModel.mutate({
							homepage,
							manufacturer,
							manufacturerContact,
							manufacturingDate,
							modelName,
							onStateDefaultImgUrl,
							offStateDefaultImgUrl,
							specialFunctions,
							supplierContact,
							supplierHomepage,
							supplierName
						})
					} else if (isOpen === '로봇 모델 설정') {
						modelsMutation.updateModel.mutate({
							modelId,
							homepage,
							manufacturer,
							manufacturerContact,
							manufacturingDate,
							modelName,
							onStateDefaultImgUrl: $onStateDefaultImgUrl,
							offStateDefaultImgUrl: $offStateDefaultImgUrl,
							specialFunctions,
							supplierContact,
							supplierHomepage,
							supplierName
						})
					}

					form.resetFields()
					setIsOpen('')
					setRobotStateImg({
						onStateDefaultImgUrl: '',
						offStateDefaultImgUrl: ''
					})
				})
				.catch(info => {
					console.log('Validate Failed:', info)
				})
		}
	}

	const vFormProps: IVFormProps = {
		model,
		specialFunctions,
		form,
		robotStateImg,
		handleChange: (selectedFunc: string, checked: boolean) => {
			// input string
			// output string[] => setSpecialFunctions
			// check와 setSpecialFunctions 배열을 확인한다.

			// nextFunctions 최신화
			let nextFunctions

			if (specialFunctions === '') {
				setSpecialFunctions(selectedFunc)
			} else {
				nextFunctions = specialFunctions.split(', ')

				// checked면 합치고 아니면 제외한다.
				if (checked) {
					nextFunctions.push(selectedFunc)
				} else {
					//
					nextFunctions = nextFunctions.filter(el => {
						return el !== selectedFunc
					})
				}

				nextFunctions = nextFunctions.join(', ')

				setSpecialFunctions(nextFunctions)
			}
		},
		handleUploadOnStateDefaultImg: (info: any) => {
			// if (info.file.status !== 'uploading') {
			// 	console.log(info.file, info.fileList)
			// }
			if (info.file.status === 'done') {
				setRobotStateImg(prev => {
					return { ...prev, onStateDefaultImgUrl: info.file.response }
				})

				// console.log(`${info.file.name} file uploaded successfully`)
			} else if (info.file.status === 'error') {
				console.log(`${info.file.name} file upload failed.`)
			}
		},
		handleUploadOffStateDefaultImg: (info: any) => {
			// if (info.file.status !== 'uploading') {
			// 	console.log(info.file, info.fileList)
			// }
			if (info.file.status === 'done') {
				setRobotStateImg(prev => {
					return { ...prev, offStateDefaultImgUrl: info.file.response }
				})

				// console.log(`${info.file.name} file uploaded successfully`)
			} else if (info.file.status === 'error') {
				console.log(`${info.file.name} file upload failed.`)
			}
		}
	}

	return (
		<Modal {...ModalProps}>
			<ProfileMutationModalFormView {...vFormProps} />
		</Modal>
	)
}

export default ModelMutationModalView
