import { Form, Input, Select, Typography } from 'antd'

import { USER_ROLE } from '../../../constants/user.constant'

function UserUpdateModalFormView({ form }: any) {
	return (
		<Form form={form} layout="horizontal" name="user_update_form_in_modal">
			<Form.Item
				className="select_in_userPage model in-modal"
				name="role"
				label="역할"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Select
					showArrow
					allowClear
					bordered
					style={{ width: '250px', height: '50px' }}>
					{USER_ROLE.map(user => {
						const user_role: { [index: string]: string } = {
							master: '슈퍼어드민',
							admin: '관리자',
							user: '일반사용자'
						}

						return (
							<Select.Option key={user} value={user}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										height: '50px'
									}}>
									<Typography.Text style={{ fontSize: '12px' }}>
										{user_role[user]}
									</Typography.Text>
								</div>
							</Select.Option>
						)
					})}
				</Select>
			</Form.Item>

			<Form.Item
				className="in-modal"
				name="userName"
				label="이름"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Input style={{ width: '250px', height: '50px' }} />
			</Form.Item>

			<Form.Item
				className="in-modal"
				name="phoneNumber"
				label="연락처"
				labelCol={{ offset: 1, span: 6 }}
				labelAlign="left"
				colon={false}
				style={{
					height: '50px'
				}}>
				<Input style={{ width: '250px', height: '50px' }} />
			</Form.Item>
		</Form>
	)
}

export default UserUpdateModalFormView
